/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/naming-convention */
import { Injectable } from '@angular/core';
import {
	breedList,
	colorList,
	genderList,
	skillsList,
	heightList,
	temperamentList,
	conditionList,
	yearList,
	saleTypeList,
	tackCategoryList,
} from '../util/data';
import { BehaviorSubject, Observable } from 'rxjs';
import { getStepSize } from '../util/price-range-utils';

@Injectable({
	providedIn: 'root',
})
export class FilterService {
	public breedList = breedList;
	public colorList = colorList;
	public genderList = genderList;
	public skillsList = skillsList;
	public heightList = heightList;
	public yearList = yearList;
	public tackCategories = tackCategoryList;
	public conditionList = conditionList;
	public temperamentList = temperamentList;
	public saleTypeList = saleTypeList;
	private askingPriceThresholds = [
		{ upperLimit: 10000, step: 100 },
		{ upperLimit: 30000, step: 500 },
		{ upperLimit: 75000, step: 1000 },
		{ upperLimit: 200000, step: 5000 },
		{ upperLimit: 500000, step: 10000 },
	];

	public topCatList: any[] = [
		{
			pk: 'horses',
			name: 'Horses',
		},
		{
			pk: 'tack',
			name: 'Tack',
		},
	];
	private displayBar = [
		// {
		// 	key: 'saleTypes',
		// 	label: 'Sale Types',
		// 	type: 'select-searchable',
		// 	canSearch: false,
		// 	canClear: true,
		// 	isMultiple: true,
		// 	itemList: this.saleTypeList,
		// 	model: [],
		// 	start_value: this.saleTypeList,
		// 	isSet: false,
		// 	source: 'displayBar',
		// 	al_key: 'sale_type',
		// },
		{
			key: 'location',
			label: 'Location',
			type: 'button',
			isSet: false,
			model: {
				address: '',
				address_2: '',
				city: '',
				state: '',
				country: '',
				postal: '',
				lat: '',
				long: '',
				range: 0,
				placeName: '',
			},
			start_value: {
				address: '',
				address_2: '',
				city: '',
				state: '',
				country: '',
				postal: '',
				lat: '',
				long: '',
				range: 0,
				placeName: '',
			},
			useCurrentLocation: false,
			source: 'displayBar',
			al_key: 'location',
		},
		{
			key: 'topCat',
			label: 'Category',
			type: 'select-searchable:custom',
			isMultiple: false,
			canSearch: false,
			canClear: false,
			itemList: this.topCatList,
			model: this.topCatList[0],
			start_value: this.topCatList[0],
			isSet: true,
			source: 'displayBar',
			al_key: '',
		},
		{
			key: 'askingPrice',
			label: 'Asking Price',
			min: 0,
			max: 50000,
			model: { lower: 0, upper: 50000 },
			start_value: { lower: 0, upper: 50000 },
			type: 'range',
			isSet: false,
			source: 'displayBar',
			step: (lower: number) => getStepSize(lower),
			isMax: true,
			isMin: true,
			dep: this.saleTypeList[0],
			depKey: 'saleTypes',
			depMet: true,
			al_key: 'asking_price',
		},
		{
			key: 'leasePrice',
			label: 'Lease Price',
			min: 0,
			max: 3000,
			model: { lower: 0, upper: 3000 },
			start_value: { lower: 0, upper: 3000 },
			type: 'range',
			isSet: false,
			source: 'displayBar',
			step: 50,
			isMax: true,
			isMin: true,
			dep: this.saleTypeList[1],
			depKey: 'saleTypes',
			depMet: true,
			al_key: 'lease_price',
		},
		{
			key: 'tradeFor',
			label: 'Trade For',
			type: 'text',
			model: '',
			start_value: '',
			isSet: false,
			source: 'displayBar',
			dep: this.saleTypeList[2],
			depKey: 'saleTypes',
			depMet: true,
			al_key: 'seeking',
		},

		{
			key: 'skills',
			label: 'Skills',
			type: 'select-searchable',
			canSearch: true,
			canClear: true,
			isMultiple: true,
			itemList: this.skillsList,
			model: [],
			start_value: [],
			isSet: false,
			source: 'displayBar',
			al_key: 'skills',
		},
	];
	private displayBarTack = [
		{
			key: 'category',
			label: 'Category',
			type: 'select-searchable',
			isMultiple: false,
			canClear: true,
			canSearch: true,
			itemList: this.tackCategories,
			model: [],
			start_value: [],
			isSet: false,
			source: 'displayBarTack',
			al_key: 'categories',
		},
		{
			key: 'brand',
			label: 'Brand',
			type: 'text',
			model: '',
			start_value: '',
			isSet: false,
			source: 'displayBarTack',
			al_key: 'brand',
		},
		{
			key: 'model',
			label: 'Model',
			type: 'text',
			model: '',
			start_value: '',
			isSet: false,
			source: 'displayBarTack',
			al_key: 'model',
		},
		{
			key: 'condition',
			label: 'Condition',
			type: 'select-searchable',
			isMultiple: true,
			canClear: true,
			canSearch: false,
			itemList: this.conditionList,
			model: [],
			start_value: [],
			isSet: false,
			source: 'displayBarTack',
			al_key: 'condition',
		},
	];
	private displayBarHorses = [
		{
			key: 'breed',
			label: 'Breed',
			type: 'select-searchable',
			isMultiple: true,
			canClear: true,
			canSearch: true,
			itemList: this.breedList,
			model: [],
			start_value: [],
			isSet: false,
			source: 'displayBarHorses',
			al_key: 'breed',
		},
		{
			key: 'color',
			label: 'Color',
			type: 'select-searchable',
			isMultiple: true,
			canClear: true,
			canSearch: true,
			itemList: this.colorList,
			model: [],
			start_value: [],
			isSet: false,
			source: 'displayBarHorses',
			al_key: 'color',
		},
		{
			key: 'gender',
			label: 'Gender',
			type: 'select-searchable',
			isMultiple: true,
			canClear: true,
			canSearch: false,
			itemList: this.genderList,
			model: [],
			start_value: [],
			isSet: false,
			source: 'displayBarHorses',
			al_key: 'gender',
		},
		{
			key: 'height',
			label: 'Height',
			min: 0,
			max: this.heightList.length - 1,
			itemList: this.heightList,
			model: { lower: 0, upper: this.heightList.length - 1 },
			start_value: { lower: 0, upper: this.heightList.length - 1 },
			type: 'range',
			isSet: false,
			source: 'displayBarHorses',
			step: 1,
			isMax: true,
			isMin: true,
			al_key: 'height',
		},
		{
			key: 'temperament',
			label: 'Temperament',
			type: 'range',
			step: () => 1,
			isSet: false,
			source: 'displayBarHorses',
			itemList: this.temperamentList,
			model: { lower: 0, upper: this.temperamentList.length - 1 },
			start_value: { lower: 0, upper: this.temperamentList.length - 1 },
			al_key: 'temperament',
		},
		{
			key: 'yearFoaled',
			label: 'Year Foaled',
			type: 'range',
			min: 0,
			max: this.yearList.length - 1,
			isSet: false,
			source: 'displayBarHorses',
			itemList: this.yearList,
			model: { lower: 0, upper: this.yearList.length - 1 },
			start_value: { lower: 0, upper: this.yearList.length - 1 },
			al_key: 'year_foaled',
		},
	];
	private filterSubject: BehaviorSubject<any> = new BehaviorSubject(this.getFilterBar());

	constructor() {}

	calculateStep(lower: number, max: number, thresholds: { upperLimit: number; step: number }[]): number {
		for (let i = thresholds.length - 1; i >= 0; i--) {
			if (lower <= thresholds[i].upperLimit) {
				return thresholds[i].step;
			}
		}
		// Default step if no threshold matches
		return thresholds[0].step;
	}

	public resetAllFilters() {
		this.displayBar.map((e)=> {
			return {
				...e,
				model: typeof e.start_value === 'object' ? { ...e.start_value } : e.start_value,
				isSet: false
			}
		})
	}

	public getFilterBar() {
		const filterBar = [...this.displayBar, ...this.displayBarHorses];
		return filterBar;
	}
	getDynamicStepFunction(max: number, thresholds: { upperLimit: number; step: number }[]) {
		return (lower: number, upper: number): number => {
			console.log('Dynamic step calculation:');
			console.log(`Lower: ${lower}, Upper: ${upper}`);
			const step = this.calculateStep(lower, max, thresholds);
			console.log(`Calculated Step: ${step}`);
			return step;
		};
	}
	getFilterBarStream(): Observable<any[]> {
		return this.filterSubject.asObservable();
	}

	setFilter(key: string, newValue: any) {
		this.updateFilterBar(key, (filter) => {
			filter.model = JSON.parse(JSON.stringify(newValue)); // Ensure deep clone
			const isSet = this.checkIfValueIsDifferent(newValue, filter.start_value);
			console.log('filter bar is doing update:');
			console.log(newValue);
			console.log(filter.start_value);
			console.log(filter.key + ': ' + isSet);
			filter.isSet = isSet;
		});
		this.notifySubscribers();
	}

	private updateFilterBar(key: string, updater: (filter: any) => void) {
		const update = (filterBar: any[]) => {
			const filter = filterBar.find((f) => f.key === key);
			if (filter) {
				updater(filter);
			}
		};
		update(this.displayBar);
		update(this.displayBarHorses);
	}

	resetFilter(key: string) {
		this.updateFilterBar(key, (filter) => {
			filter.model = JSON.parse(JSON.stringify(filter.start_value));
			filter.isSet = false;
		});
		this.notifySubscribers();
	}

	private notifySubscribers() {
		this.filterSubject.next(this.getFilterBar());
	}

	private checkIfValueIsDifferent(currentValue: any, startValue: any): boolean {
		console.log('Checking if values are different:', { currentValue, startValue });

		if (typeof currentValue !== typeof startValue) {
			console.log('Different types:', { currentType: typeof currentValue, startType: typeof startValue });
			return true;
		}

		// Deep comparison for arrays
		if (Array.isArray(currentValue) && Array.isArray(startValue)) {
			if (currentValue.length !== startValue.length) {
				console.log('Arrays have different lengths:', { currentLength: currentValue.length, startLength: startValue.length });
				return true;
			}
			const arraysEqual = currentValue.every((val, index) => val === startValue[index]);
			if (!arraysEqual) {
				console.log('Arrays have different values:', { currentValue, startValue });
			}
			return !arraysEqual;
		}

		// Deep comparison for objects
		if (typeof currentValue === 'object' && currentValue !== null && startValue !== null) {
			const currentKeys = Object.keys(currentValue);
			const startKeys = Object.keys(startValue);

			if (currentKeys.length !== startKeys.length) {
				console.log('Objects have different numbers of keys:', { currentKeys, startKeys });
				return true;
			}

			const keyDifference = currentKeys.some((key) => {
				const isDifferent = this.checkIfValueIsDifferent(currentValue[key], startValue[key]);
				if (isDifferent) {
					console.log('Objects have different values for key:', {
						key,
						currentValue: currentValue[key],
						startValue: startValue[key],
					});
				}
				return isDifferent;
			});
			return keyDifference;
		}

		// Primitive comparison
		const primitiveDifference = currentValue !== startValue;
		if (primitiveDifference) {
			console.log('Primitive values are different:', { currentValue, startValue });
		}
		return primitiveDifference;
	}

	// private checkIfValueIsDifferent(currentValue: any, startValue: any): boolean {
	// 	if (typeof currentValue !== typeof startValue) return true;

	// 	// Deep comparison for arrays
	// 	if (Array.isArray(currentValue) && Array.isArray(startValue)) {
	// 		if (currentValue.length !== startValue.length) return true;
	// 		return !currentValue.every((val, index) => val === startValue[index]);
	// 	}

	// 	// Deep comparison for objects
	// 	if (typeof currentValue === 'object' && currentValue !== null && startValue !== null) {
	// 		const currentKeys = Object.keys(currentValue);
	// 		const startKeys = Object.keys(startValue);
	// 		if (currentKeys.length !== startKeys.length) return true;

	// 		return currentKeys.some((key) => !this.checkIfValueIsDifferent(currentValue[key], startValue[key]));
	// 	}

	// 	// Primitive comparison
	// 	return currentValue !== startValue;
	// }

	// public setFilter(key: string, newValue: any) {
	// 	const updateFilterBar = (filterBar: any[]) => {
	// 		const item = filterBar.find((f) => f.key === key);
	// 		if (item) {
	// 			item.model = newValue;
	// 			item.isSet = this.checkIfValueIsSet(newValue);
	// 		}
	// 	};

	// 	updateFilterBar(this.displayBar);
	// 	updateFilterBar(this.displayBarHorses);
	// 	updateFilterBar(this.displayBarTack);

	// 	this.filterSubject.next(this.getFilterBar());
	// }

	// private checkIfValueIsSet(value: any): boolean {
	// 	if (value === null || value === undefined) return false;
	// 	if (Array.isArray(value)) return value.length > 0;
	// 	if (typeof value === 'object') {
	// 		return Object.keys(value).length > 0 && Object.values(value).some((v) => v !== '' && v !== 0);
	// 	}
	// 	return value !== '';
	// }

	public unsetFilter(key: string) {
		const unsetInFilterBar = (filterBar: any[]) => {
			const item = filterBar.find((f) => f.key === key);
			console.log('unsetFilter:');
			console.log(item);
			if (item) {
				item.model = item.start_value ? JSON.parse(JSON.stringify(item.start_value)) : null;
				item.isSet = false;
			}
		};

		unsetInFilterBar(this.displayBar);
		unsetInFilterBar(this.displayBarHorses);
		unsetInFilterBar(this.displayBarTack);

		this.filterSubject.next(this.getFilterBar());
	}

	// prepareAlgoliaFilters() {
	// 	const displayBar = this.getFilterBar();
	// 	const displayBarSet = displayBar.filter((item) => item.isSet);

	// 	console.log('displayBarSet:');
	// 	console.log(displayBarSet);

	// 	const groupBy = (array, key) =>
	// 		array.reduce((result, currentValue) => {
	// 			(result[currentValue[key]] = result[currentValue[key]] || []).push(currentValue);
	// 			return result;
	// 		}, {});

	// 	const groupedItems = groupBy(
	// 		displayBarSet.filter((item) => item.key !== 'topCat'),
	// 		'key'
	// 	);

	// 	const filterStrings = Object.keys(groupedItems)
	// 		.map((key) => {
	// 			const items = groupedItems[key];

	// 			const values = items.flatMap((item) => {
	// 				if (Array.isArray(item.model)) {
	// 					return item.model.map((val) => `${key}:${val.pk}`);
	// 				} else if (typeof item.model !== 'undefined' && typeof item.model.pk !== 'undefined') {
	// 					return `${key}:${item.model.pk}`;
	// 				}
	// 				return [];
	// 			});

	// 			return values.length > 0 ? values.join(' OR ') : '';
	// 		})
	// 		.filter(Boolean);

	// 	console.log('filterStrings:');
	// 	console.log(filterStrings);

	// 	return filterStrings.join(' AND ');
	// }

	// prepareAlgoliaFilters(): string {
	// 	const displayBar = this.getFilterBar();
	// 	const displayBarSet = displayBar.filter((item) => item.isSet); // Only include active filters

	// 	console.log('Active Filters (isSet = true):', displayBarSet);

	// 	// Group filters by key, excluding 'topCat'
	// 	const groupBy = (array: any[], key: string) =>
	// 		array.reduce((result, currentValue) => {
	// 			(result[currentValue[key]] = result[currentValue[key]] || []).push(currentValue);
	// 			return result;
	// 		}, {});

	// 	const groupedItems = groupBy(
	// 		displayBarSet.filter((item) => item.key !== 'topCat'), // Exclude 'topCat' from processing
	// 		'key'
	// 	);

	// 	// Construct filter strings
	// 	const filterStrings = Object.keys(groupedItems)
	// 		.map((key) => {
	// 			const items = groupedItems[key];

	// 			const values = items.flatMap((item) => {
	// 				if (Array.isArray(item.model)) {
	// 					// Handle array-based models
	// 					return item.model.map((val) => `${key}:${val.pk}`);
	// 				} else if (item.type === 'range' && item.model.lower !== undefined && item.model.upper !== undefined) {
	// 					// Handle range filters
	// 					return `${key} >= ${item.model.lower} AND ${key} <= ${item.model.upper}`;
	// 				} else if (item.model && typeof item.model.pk !== 'undefined') {
	// 					// Handle object-based models with 'pk' properties
	// 					return `${key}:${item.model.pk}`;
	// 				}
	// 				return [];
	// 			});

	// 			return values.length > 0 ? values.join(' OR ') : '';
	// 		})
	// 		.filter(Boolean); // Remove empty strings

	// 	console.log('Generated Filter Strings:', filterStrings);

	// 	// Combine filter strings with 'AND'
	// 	return filterStrings.join(' AND ');
	// }

	// prepareAlgoliaFilters(): string {
	// 	const displayBar = this.getFilterBar();

	// 	// Only include filters that are active (isSet = true)
	// 	const displayBarSet = displayBar.filter((item) => item.isSet);

	// 	console.log('Active Filters (isSet = true):', displayBarSet);

	// 	// Group filters by their key, excluding 'topCat' and 'location'
	// 	const groupBy = (array: any[], key: string) =>
	// 		array.reduce((result, currentValue) => {
	// 			(result[currentValue[key]] = result[currentValue[key]] || []).push(currentValue);
	// 			return result;
	// 		}, {});

	// 	const groupedItems = groupBy(
	// 		displayBarSet.filter((item) => item.key !== 'topCat' && item.key !== 'location'),
	// 		'key'
	// 	);

	// 	// Build filter strings for Algolia
	// 	const filterStrings = Object.keys(groupedItems)
	// 		.map((key) => {
	// 			const items = groupedItems[key];

	// 			// Build filter values for each group
	// 			const values = items.flatMap((item) => {
	// 				if (Array.isArray(item.model)) {
	// 					// Handle array-based models
	// 					return item.model.map((val) => `${key}:${val.pk}`);
	// 				} else if (item.type === 'range' && item.model.lower !== undefined && item.model.upper !== undefined) {
	// 					// Handle range filters
	// 					return `${key} >= ${item.model.lower} AND ${key} <= ${item.model.upper}`;
	// 				} else if (item.model && typeof item.model.pk !== 'undefined') {
	// 					// Handle object-based models with 'pk' properties
	// 					return `${key}:${item.model.pk}`;
	// 				}
	// 				return [];
	// 			});

	// 			return values.length > 0 ? values.join(' OR ') : ''; // Join multiple values with 'OR'
	// 		})
	// 		.filter(Boolean); // Remove empty strings

	// 	console.log('Generated Filter Strings:', filterStrings);

	// 	// Combine all filters with 'AND'
	// 	return filterStrings.join(' AND ');
	// }

	// prepareAlgoliaFilters(): string {
	// 	const displayBar = this.getFilterBar();
	// 	const displayBarSet = displayBar.filter((item) => item.isSet);
	// 	console.log('Active Filters (isSet = true):', JSON.stringify(displayBarSet, null, 2));
	// 	const groupBy = (array: any[], key: string) =>
	// 		array.reduce((result, currentValue) => {
	// 			(result[currentValue[key]] = result[currentValue[key]] || []).push(currentValue);
	// 			return result;
	// 		}, {});
	// 	const groupedItems = groupBy(
	// 		displayBarSet.filter((item) => item.key !== 'topCat' && item.key !== 'location'),
	// 		'key'
	// 	);
	// 	console.log('Grouped Filters:', JSON.stringify(groupedItems, null, 2));
	// 	const filterStrings = Object.keys(groupedItems)
	// 		.map((key) => {
	// 			const items = groupedItems[key];
	// 			const values = items.flatMap((item) => {
	// 				if (Array.isArray(item.model)) {
	// 					return item.model.map((val) => `${item.al_key}:${val.pk}`);
	// 				} else if (item.type === 'range' && item.model.lower !== undefined && item.model.upper !== undefined) {
	// 					return `${item.al_key} >= ${item.model.lower} AND ${item.al_key} <= ${item.model.upper}`;
	// 				} else if (item.model && typeof item.model.pk !== 'undefined') {
	// 					return `${item.al_key}:${item.model.pk}`;
	// 				}
	// 				return [];
	// 			});
	// 			return values.length > 0 ? values.join(' OR ') : '';
	// 		})
	// 		.filter(Boolean);
	// 	console.log('Final Filter Strings:', JSON.stringify(filterStrings, null, 2));
	// 	return filterStrings.join(' AND ');
	// }

	// prepareAlgoliaFilters(): any {
	// 	const displayBar = this.getFilterBar();
	// 	const displayBarSet = displayBar.filter((item) => item.isSet);

	// 	console.log('Active Filters (isSet = true):', JSON.stringify(displayBarSet, null, 2));

	// 	// Collect only the active facets
	// 	const facets = displayBarSet.map((item) => item.al_key).filter((key) => key !== undefined && key !== '');

	// 	console.log('Active Facets:', JSON.stringify(facets, null, 2));

	// 	// Group items by key for better handling
	// 	const groupBy = (array: any[], key: string) =>
	// 		array.reduce((result, currentValue) => {
	// 			(result[currentValue[key]] = result[currentValue[key]] || []).push(currentValue);
	// 			return result;
	// 		}, {});

	// 	const groupedItems = groupBy(
	// 		displayBarSet.filter((item) => item.key !== 'topCat'), // Exclude 'topCat' from processing
	// 		'key'
	// 	);

	// 	console.log('Grouped Filters:', JSON.stringify(groupedItems, null, 2));

	// 	const filterStrings = Object.keys(groupedItems)
	// 		.map((key) => {
	// 			const items = groupedItems[key];
	// 			const values = items.flatMap((item) => {
	// 				if (item.type === 'range' && item.model.lower !== undefined && item.model.upper !== undefined) {
	// 					return `${item.al_key} >= ${item.model.lower} AND ${item.al_key} <= ${item.model.upper}`;
	// 				} else if (Array.isArray(item.model)) {
	// 					return item.model.map((val) => `${item.al_key}:${val.pk}`);
	// 				} else if (item.model && typeof item.model.pk !== 'undefined') {
	// 					return `${item.al_key}:${item.model.pk}`;
	// 				}
	// 				return [];
	// 			});
	// 			return values.length > 0 ? values.join(' OR ') : '';
	// 		})
	// 		.filter(Boolean);

	// 	console.log('Generated Filter Strings:', JSON.stringify(filterStrings, null, 2));

	// 	const filterString = filterStrings.join(' AND ');

	// 	const finalObject = {
	// 		facets: facets.length > 0 ? facets : ['*'], // Include '*' if no facets are set, else use active facets
	// 		facetFilters: [], // This remains empty unless categorical filters are active
	// 		hitsPerPage: 10,
	// 		page: 0,
	// 		filters: filterString,
	// 	};

	// 	console.log('Final Prepared Object for Algolia:', JSON.stringify(finalObject, null, 2));

	// 	return finalObject;
	// }

	// prepareAlgoliaFilters(): any {
	// 	const displayBar = this.getFilterBar();
	// 	const displayBarSet = displayBar.filter((item) => item.isSet);

	// 	console.log('Active Filters (isSet = true):', JSON.stringify(displayBarSet, null, 2));

	// 	// Collect only the active facets
	// 	const facets = displayBarSet.map((item) => item.al_key).filter((key) => key !== undefined && key !== '');

	// 	console.log('Active Facets:', JSON.stringify(facets, null, 2));

	// 	// Group items by key for better handling
	// 	const groupBy = (array: any[], key: string) =>
	// 		array.reduce((result, currentValue) => {
	// 			(result[currentValue[key]] = result[currentValue[key]] || []).push(currentValue);
	// 			return result;
	// 		}, {});

	// 	const groupedItems = groupBy(
	// 		displayBarSet.filter((item) => item.key !== 'topCat'), // Exclude 'topCat' from processing
	// 		'key'
	// 	);

	// 	console.log('Grouped Filters:', JSON.stringify(groupedItems, null, 2));

	// 	const filterStrings = Object.keys(groupedItems)
	// 		.map((key) => {
	// 			const items = groupedItems[key];
	// 			const values = items.flatMap((item) => {
	// 				if (item.type === 'range' && item.model.lower !== undefined && item.model.upper !== undefined) {
	// 					// For temperament with itemList, use the value property
	// 					if (item.key === 'temperament' && item.itemList) {
	// 						const lowerValue = item.itemList[item.model.lower]?.value;
	// 						const upperValue = item.itemList[item.model.upper]?.value;

	// 						// If lower equals upper, do exact match instead of range
	// 						if (lowerValue === upperValue) {
	// 							return `${item.al_key} = ${lowerValue}`;
	// 						}
	// 						return `${item.al_key} >= ${lowerValue} AND ${item.al_key} <= ${upperValue}`;
	// 					}

	// 					// For height with itemList
	// 					if (item.key === 'height' && item.itemList) {
	// 						const lowerValue = item.itemList[item.model.lower]?.value;
	// 						const upperValue = item.itemList[item.model.upper]?.value;

	// 						// If lower equals upper, do exact match instead of range
	// 						if (lowerValue === upperValue) {
	// 							return `${item.al_key} = ${lowerValue}`;
	// 						}
	// 						return `${item.al_key} >= ${lowerValue} AND ${item.al_key} <= ${upperValue}`;
	// 					}

	// 					// For other range types like price
	// 					if (item.model.lower === item.model.upper) {
	// 						return `${item.al_key} = ${item.model.lower}`;
	// 					}
	// 					return `${item.al_key} >= ${item.model.lower} AND ${item.al_key} <= ${item.model.upper}`;
	// 				} else if (Array.isArray(item.model)) {
	// 					return item.model.map((val) => `${item.al_key}:${val.pk}`);
	// 				} else if (item.model && typeof item.model.pk !== 'undefined') {
	// 					return `${item.al_key}:${item.model.pk}`;
	// 				}
	// 				return [];
	// 			});
	// 			return values.length > 0 ? values.join(' OR ') : '';
	// 		})
	// 		.filter(Boolean);

	// 	console.log('Generated Filter Strings:', JSON.stringify(filterStrings, null, 2));

	// 	const filterString = filterStrings.join(' AND ');

	// 	const finalObject = {
	// 		facets: facets.length > 0 ? facets : ['*'], // Include '*' if no facets are set, else use active facets
	// 		facetFilters: [], // This remains empty unless categorical filters are active
	// 		hitsPerPage: 10,
	// 		page: 0,
	// 		filters: filterString,
	// 	};

	// 	console.log('Final Prepared Object for Algolia:', JSON.stringify(finalObject, null, 2));

	// 	return finalObject;
	// }

	// prepareAlgoliaFilters(): any {
	// 	const displayBar = this.getFilterBar();
	// 	const displayBarSet = displayBar.filter((item) => item.isSet);

	// 	console.log('Active Filters (isSet = true):', JSON.stringify(displayBarSet, null, 2));

	// 	// Collect only the active facets
	// 	const facets = displayBarSet
	// 		.map((item) => item.al_key)
	// 		.filter((key) => key !== undefined && key !== '');

	// 	console.log('Active Facets:', JSON.stringify(facets, null, 2));

	// 	// Group items by key for better handling
	// 	const groupBy = (array: any[], key: string) =>
	// 		array.reduce((result, currentValue) => {
	// 			(result[currentValue[key]] = result[currentValue[key]] || []).push(currentValue);
	// 			return result;
	// 		}, {});

	// 	const groupedItems = groupBy(
	// 		displayBarSet.filter((item) => item.key !== 'topCat'),
	// 		'key'
	// 	);

	// 	console.log('Grouped Filters:', JSON.stringify(groupedItems, null, 2));

	// 	const filterStrings = Object.keys(groupedItems)
	// 		.map((key) => {
	// 			const items = groupedItems[key];
	// 			const values = items.flatMap((item) => {
	// 				if (item.type === 'range' && item.model.lower !== undefined && item.model.upper !== undefined) {
	// 					// Handle range filters with itemLists (height, temperament, yearFoaled)
	// 					if (['height', 'temperament', 'yearFoaled'].includes(item.key) && item.itemList) {
	// 						let lowerValue, upperValue;

	// 						if (item.key === 'yearFoaled') {
	// 							// For yearFoaled, use the direct values since they're already year numbers
	// 							lowerValue = item.model.lower;
	// 							upperValue = item.model.upper;
	// 						} else {
	// 							// For height and temperament, get values from itemList
	// 							lowerValue = item.itemList[item.model.lower]?.value;
	// 							upperValue = item.itemList[item.model.upper]?.value;
	// 						}

	// 						if (lowerValue === upperValue) {
	// 							return `${item.al_key} = ${lowerValue}`;
	// 						}
	// 						return `${item.al_key} >= ${lowerValue} AND ${item.al_key} <= ${upperValue}`;
	// 					}

	// 					// For other range types like price
	// 					if (item.model.lower === item.model.upper) {
	// 						return `${item.al_key} = ${item.model.lower}`;
	// 					}
	// 					return `${item.al_key} >= ${item.model.lower} AND ${item.al_key} <= ${item.model.upper}`;
	// 				} else if (Array.isArray(item.model)) {
	// 					return item.model.map((val) => `${item.al_key}:${val.pk}`);
	// 				} else if (item.model && typeof item.model.pk !== 'undefined') {
	// 					return `${item.al_key}:${item.model.pk}`;
	// 				}
	// 				return [];
	// 			});
	// 			return values.length > 0 ? values.join(' OR ') : '';
	// 		})
	// 		.filter(Boolean);

	// 	console.log('Generated Filter Strings:', JSON.stringify(filterStrings, null, 2));

	// 	const filterString = filterStrings.join(' AND ');

	// 	const finalObject = {
	// 		facets: facets.length > 0 ? facets : ['*'],
	// 		facetFilters: [],
	// 		hitsPerPage: 10,
	// 		page: 0,
	// 		filters: filterString,
	// 	};

	// 	console.log('Final Prepared Object for Algolia:', JSON.stringify(finalObject, null, 2));

	// 	return finalObject;
	// }

	prepareAlgoliaFilters(): any {
		const displayBar = this.getFilterBar();
		const displayBarSet = displayBar.filter((item) => item.isSet);

		// console.log('Active Filters (isSet = true):', JSON.stringify(displayBarSet, null, 2));

		// Collect only the active facets
		const facets = displayBarSet.map((item) => item.al_key).filter((key) => key !== undefined && key !== '');

		// console.log('Active Facets:', JSON.stringify(facets, null, 2));

		// Group items by key for better handling
		const groupBy = (array: any[], key: string) =>
			array.reduce((result, currentValue) => {
				(result[currentValue[key]] = result[currentValue[key]] || []).push(currentValue);
				return result;
			}, {});

		const groupedItems = groupBy(
			displayBarSet.filter((item) => item.key !== 'topCat'),
			'key'
		);

		// console.log('Grouped Filters:', JSON.stringify(groupedItems, null, 2));

		const filterStrings = Object.keys(groupedItems)
			.map((key) => {
				const items = groupedItems[key];
				const values = items.flatMap((item) => {
					if (item.type === 'range' && item.model.lower !== undefined && item.model.upper !== undefined) {
						if (['height', 'temperament', 'yearFoaled'].includes(item.key) && item.itemList) {
							const lowerIndex = Math.min(Math.max(0, item.model.lower), item.itemList.length - 1);
							const upperIndex = Math.min(Math.max(0, item.model.upper), item.itemList.length - 1);
							const lowerValue = item.itemList[lowerIndex]?.value;
							const upperValue = item.itemList[upperIndex]?.value;

							if (lowerValue === upperValue) {
								return `${item.al_key} = ${lowerValue}`;
							}
							return `${item.al_key} >= ${lowerValue} AND ${item.al_key} <= ${upperValue}`;
						}

						// For other range types like price
						if (item.model.lower === item.model.upper) {
							return `${item.al_key} = ${item.model.lower}`;
						}
						return `${item.al_key} >= ${item.model.lower} AND ${item.al_key} <= ${item.model.upper}`;
					} else if (Array.isArray(item.model)) {
						return item.model.map((val) => `${item.al_key}:${val.pk}`);
					} else if (item.model && typeof item.model.pk !== 'undefined') {
						return `${item.al_key}:${item.model.pk}`;
					}
					return [];
				});
				return values.length > 0 ? values.join(' OR ') : '';
			})
			.filter(Boolean);

		// console.log('Generated Filter Strings:', JSON.stringify(filterStrings, null, 2));

		const filterString = filterStrings.join(' AND ');

		const finalObject = {
			facets: facets.length > 0 ? facets : ['*'],
			facetFilters: [],
			hitsPerPage: 10,
			page: 0,
			filters: filterString,
		};

		// console.log('Final Prepared Object for Algolia:', JSON.stringify(finalObject, null, 2));

		return finalObject;
	}
}
