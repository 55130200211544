import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { AuthService } from './auth.service';
import { Token } from './auth.types';

@Injectable({
	providedIn: 'root',
})
export class TokenManagerService {
	private isRefreshing = false;
	private refreshTokenSubject = new BehaviorSubject<string | null>(null);

	constructor(private authService: AuthService) {}

	/**
	 * Get the current access token as an observable.
	 */
	getAccessToken(): Observable<string | null> {
		const token = this.authService.token?.access || null;
		this.refreshTokenSubject.next(token); // Ensure the latest token is emitted
		return this.refreshTokenSubject.asObservable();
	}

	/**
	 * Refresh the access token using the refresh token.
	 */
	refreshToken(): Observable<string> {
		if (this.isRefreshing) {
			return this.refreshTokenSubject.asObservable();
		}

		const refreshToken = this.authService.token?.refresh;
		if (!refreshToken) {
			return throwError(() => new Error('No refresh token available.'));
		}

		this.isRefreshing = true;

		return this.authService.renewToken().pipe(
			tap((newToken: Token) => {
				this.isRefreshing = false;
				this.refreshTokenSubject.next(newToken.access); // Emit the new token
			}),
			map((newToken: Token) => newToken.access),
			catchError((error) => {
				this.isRefreshing = false;
				this.refreshTokenSubject.next(null); // Clear the token on failure
				return throwError(() => error);
			})
		);
	}

	/**
	 * Get the refresh token.
	 */
	private getRefreshToken(): string | null {
		return this.authService.token?.refresh || null;
	}

	/**
	 * Log out the user and clear any token-related state.
	 */
	logout(): void {
		this.authService.logout();
		this.refreshTokenSubject.next(null);
	}
}
