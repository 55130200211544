/* eslint-disable no-underscore-dangle */
import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import ReconnectingWebSocket from 'reconnecting-websocket';
import { RestService } from '../rest/rest.service';
import { Store } from '@ngrx/store';
import * as fromApp from '../../app.reducer';
import { Subscription, Subject, Observable } from 'rxjs';
import { Token, TokenMaker } from '../auth/auth.types';
declare let require: any;
import { UtilService } from '../util/util.service';
import { MessageMaker } from '../socket/socket.types';
import { RestError } from '../rest/rest.types';
import { StorageService } from '../storage/storage.service';
import * as AuthActions from '../auth/store/auth.actions';
import { User } from '../user/user.service';
import { map } from 'rxjs/operators';
import { AuthService } from '../auth/auth.service';

@Injectable({
	providedIn: 'root',
})
export class SocketService {
	public socketAuthSubject: Subject<any> = new Subject<any>();
	public socketSubject: Subject<any> = new Subject<any>();
	public socketTypingSubject: Subject<any> = new Subject<any>();
	public socketReadSubject: Subject<any> = new Subject<any>();
	private chatServiceSubject = new Subject<any>();
	public chatServiceObservable = this.chatServiceSubject.asObservable();

	private messageUpdateSubject = new Subject<any>();
	public messageUpdateObservable = this.messageUpdateSubject.asObservable();

	private socket: any;
	private awaitingEvent: any = null;
	private awaitingResolve: any;
	private filter: any;

	private _token: Token | null = null;
	private _tokenStorageSubscription: Subscription;
	private storeSubscription: Subscription;
	private refreshing = false;

	private createThreadResolve: any;
	private awaitingThreadCreateReceiver: any;

	// private awaitingThreadMessageSend: string;
	// private awaitingMessageSend: string;
	private pendingMessageQueue: {
		tempPk: string;
		thread: string;
		content: string;
		resolve: (value: any) => void;
		reject: (reason?: any) => void;
	}[] = [];

	private pendingMessages = new Map<string, { resolve: (value: any) => void; reject: (reason?: any) => void }>();

	private _user: User;

	constructor(
		private restService: RestService,
		private store: Store<fromApp.AppState>,
		private util: UtilService,
		private storageService: StorageService,
		private authService: AuthService
	) {
		this.startAuthSubscription();
		this.initializeUserSubscription();
	}

	public get token(): Token | null {
		return this._token;
	}

	public set token(token: Token | null) {
		this._token = token;
	}

	private initializeUserSubscription() {
		this.store
			.select('user')
			.pipe(map((e) => e.user))
			.subscribe((user) => {
				if (user) {
					this._user = user;
				}
			});
	}

	private isMine(pk: string): boolean {
		return pk === this._user.pk;
	}

	public init() {
		console.log('~~~~~~ REQUESTED CHAT SOCKET CONNECTION ~~~~~~~~');

		if (!this.filter) {
			const FILTER = require('bad-words');
			this.filter = new FILTER();
			this.prepareBadWords();
		}

		if (!this.token) {
			console.warn('TOKEN NOT SET!');
			return;
		}

		if (this.socket) {
			this.socket.reconnect(1, 'onLogin');
		} else {
			this.socket = new ReconnectingWebSocket(this.urlProvider, [], {
				connectionTimeout: 5000,
				reconnectionDelayGrowFactor: 1.2,
				minReconnectionDelay: 1,
			});
			this.socket.onerror = (e: any) => {
				console.log('Chat socket ERROR!');
				console.log(e);
			};

			this.socket.onclose = (e: any) => {
				console.error('Chat socket closed!');
				/*
                    TODO: flip a flag telling us to re-query the chat messages (if we do connect again)
                        to catch any messages that came thru while socket was refreshing.
        
                        if internet is fast, usually only miss typing indicators,
                        but if slow, could miss messages.
                */
				console.log(e);
			};

			this.socket.onopen = (e: any) => {
				console.log('%c🚀 Chat socket opened! Hooray! 💬', 'font-size:1.2rem; padding:0.4rem;');
				if (this.awaitingEvent) {
					this.awaitingEvent().then(() => {
						console.log('finished the awaiting event!');
						this.awaitingEvent = null;
					});
				}
			};

			this.socket.onmessage = (e: any) => {
				console.log('onmessage!');
				const response = JSON.parse(e.data);
				this.socketPayloadType(response);
				if (typeof response.sender !== 'undefined' && !this.isMine(response.sender)) {
					console.log(response);
				}
			};

			console.log('finished init for chat socket');
			console.log(this.urlProvider());
		}
	}

	public startAuthSubscription() {
		this.storeSubscription = this.store.select('auth').subscribe((authState) => {
			// console.log('socket service storeSubscription:');
			// console.log(JSON.stringify(authState, null, 2));

			if (this.token && authState.access && this.token.access && authState.access !== this.token.access) {
				// eslint-disable-next-line max-len
				console.log(
					'%csocket received a NeW tOkEn!',
					'font-size:1.6rem padding:0.5rem; background: white;color:red; font-family:"Tahoma", sans-serif;'
				);
				this.token = TokenMaker.create(authState);

				// manually tell socket to reconnect? - yes
				// should we re-query for messages we missed? - yes

				if (this.refreshing) {
					// we called for the refresh! now handle it
					this.refreshing = false;
					if (this.awaitingEvent) {
						this.awaitingEvent().then(() => {
							console.log('finished the awaiting event!');
							this.awaitingEvent = null;
						});
					}
				}
			}
			if (this.token == null && authState.access && authState.refresh) {
				this.token = TokenMaker.create(authState);
				console.log('+-+-+- +-+-+- +-+-+- +-+-+- +-+-+- +-+-+- +-+-+-');
				console.log('+-+-+- set initial token on socket service -+-+- ');
				console.log('+-+-+- +-+-+- +-+-+- +-+-+- +-+-+- +-+-+- +-+-+-');
				console.log(this.token);
			} else if (this.token !== null && !authState.access && !authState.refresh) {
				this.token = null;
			}
		});
	}

	// public onNewThreadCreated(thread: any): void {
	// 	this.chatServiceSubject.next(thread);
	// }

	send(data: any): Promise<any> {
		return new Promise<void>((resolve, reject) => {
			if (!this._token) {
				console.log('no token is set! cannot send via socket.');
				reject();
			}

			if (this.refreshing) {
				console.log('is already refreshing, cannot start again!!');
				reject();
			}

			if (this._token && this.util.tokenExpired(this._token.access) && this._token.refresh) {
				console.log('need to renew the token before continuing with socket');
				this.refreshing = true;
				this.awaitingEvent = () => this.send(data);
				this.socketAuthSubject.next();
			} else {
				this.socket.send(JSON.stringify(data));
				resolve();
			}
		});
	}

	/* Called from chat.page.ts */
	public sendMessageWithConfirmation(message: string, threadPk: string, tempPk: string, listing?: any): Promise<any> {
		return new Promise((resolve, reject) => {
			this.pendingMessageQueue.push({
				tempPk,
				thread: threadPk,
				content: message,
				resolve,
				reject,
			});

			console.log('sendMessage...');

			let payload: any = {
				type: 'chat.send_message',
				thread: threadPk,
				content: message,
			};

			if (listing) {
				payload = {
					...payload,
					data: {
						pk: listing.pk,
						type: listing.type,
					},
				};
				console.log('added listing to outgoing message');
				console.log(payload);
			}

			this.send(payload);
		});
	}

	sendTyping(threadPk: string): Promise<any> {
		return this.send({
			type: 'chat.send_typing',
			thread: threadPk,
		});
	}

	sendRead(threadPk: string, messagePk: string): Promise<any> {
		return this.send({
			type: 'chat.send_message_read',
			thread: threadPk,
			message: messagePk,
		});
	}

	createThread(receiver: any, message: string, listing: any): Promise<any> {
		return new Promise((resolve) => {
			this.createThreadResolve = resolve;
			this.awaitingThreadCreateReceiver = receiver;
			this.send({
				type: 'chat.create_thread',
				['other_participants']: [receiver.pk],
			});
		});
	}

	stopSocket(reason: string = 'stopSocket') {
		if (this.socket) {
			this.socket.close(4011, reason);
		}
	}

	public cleanText(text) {
		if (this.filter) {
			return this.filter.clean(text);
		}
		return false;
	}

	private socketPayloadType(response: any) {
		const type = response.type;
		if (!type) {
			return false;
		}

		switch (type) {
			case 'auth.invalid_user':
				this.handleInvalidAuth(response); // both cases should invoke renewal attempt
				break;
			case 'chat.invalid_token':
				this.handleInvalidAuth(response); // both cases should invoke renewal attempt
				break;
			case 'chat.message':
				this.handleSocketMessage(response);
				break;
			case 'chat.message_read':
				this.handleSocketMessageRead(response);
				break;
			case 'chat.typing':
				this.handleSocketTyping(response);
				break;
			case 'chat.thread':
				this.handleSocketThread(response);
				break;
			default:
				break;
		}
	}

	private handleSocketThread(response: any) {
		console.log('handleSocketThread()');
		console.log(response);
		console.log('this.awaitingThreadCreateReceiver:');
		console.log(this.awaitingThreadCreateReceiver);

		if (this.isThreadWeLastCreated(response)) {
			if (this.createThreadResolve) {
				console.log('resolving thread create');
				this.createThreadResolve(response);
				this.createThreadResolve = null;

				console.log('thread object from server:');
				console.log(response);

				const doSendThis = {
					pk: response.pk,
					other_participants: [this.awaitingThreadCreateReceiver],
					latest_read: null,
					latest_message: null,
				};

				console.log(doSendThis);

				/* place new thread object in chat.service */
				this.chatServiceSubject.next({
					...response,
					other_participants: [this.awaitingThreadCreateReceiver],
				});
				this.awaitingThreadCreateReceiver = null;
			}
		} else {
			// add a new thread to host a new user interaction
			console.log(response);
			this.chatServiceSubject.next(response);
		}
	}

	// private prepareNewThread() {
	// 	const newThreadObject = {
	// 		pk: data.pk,
	// 		other_participants: [this.receiver],
	// 		latest_read: null,
	// 		latest_message: null,
	// 	};

	// }

	private urlProvider = (): string => {
		const token = this.token;
		// return `${environment.websocket_local}?token=${token?.access}`;
		return `${environment.websocket_url2}?token=${token?.access}`;
	};

	private handleSocketMessage(response: any) {
		const matchedPending = this.findPendingMessageByContent(response);
		/* swap the temporary pk used for tracking with actual from db */
		/* must swap the pk before calling socketSubject.next() */
		if (matchedPending) {
			console.log('matchedPending found', matchedPending);
			matchedPending.resolve(response);
			this.messageUpdateSubject.next({ tempPk: matchedPending.tempPk, updatedMessage: response });
			this.pendingMessageQueue = this.pendingMessageQueue.filter((item) => item !== matchedPending);
		}
		console.log('fire this.socketSubject.next(response)');
		this.socketSubject.next(response);
	}

	private handleSocketMessageRead(response: any) {
		this.socketReadSubject.next(response);
	}

	private handleSocketTyping(response: any) {
		if (!this.isMine(response)) {
			this.socketTypingSubject.next(response);
		}
	}

	private handleInvalidAuth(response: any) {
		console.log('handleInvalidAuth()', 'font-size:1.2rem; padding:0.5rem;');
		console.log(response);

		/* prevent socket from requesting multiple refreshes and burning a token */
		if (this.refreshing || this.authService.isRefreshing) {
			console.warn('Already refreshing, ignoring request');
			return false;
		}
		if (!this.token || !this.token.refresh) {
			console.log('no token for socket to refresh with');
			console.log(response);
			this.stopSocket('No auth token');
			return false;
		}
		this.socketAuthSubject.next();
	}

	private findPendingMessageByContent(response: any): any | undefined {
		return this.pendingMessageQueue.find((pending) => {
			return response.thread === pending.thread && response.content === pending.content;
		});
	}

	private isThreadWeLastCreated(response: any): boolean {
		// console.log('isThreadWeLastCreated()');
		if (!this.awaitingThreadCreateReceiver) {
			return false;
		}
		const receiverPk = response.other_participants[0];
		// console.log(this.awaitingThreadCreateReceiver);
		// console.log(receiverPk);
		// console.log(this.awaitingThreadCreateReceiver.pk + ' === ' + receiverPk);
		// console.log(receiverPk === this.awaitingThreadCreateReceiver.pk);
		return receiverPk === this.awaitingThreadCreateReceiver.pk;
	}

	private prepareBadWords() {
		this.filter.removeWords('semen', 'poop');
	}
}
// /* eslint-disable no-underscore-dangle */
// import { Injectable } from '@angular/core';
// import { environment } from '../../../environments/environment';
// import ReconnectingWebSocket from 'reconnecting-websocket';
// import { RestService } from '../rest/rest.service';
// import { Store } from '@ngrx/store';
// import * as fromApp from '../../app.reducer';
// import { Subscription, Subject, Observable } from 'rxjs';
// import { Token, TokenMaker } from '../auth/auth.types';
// declare let require: any;
// import { UtilService } from '../util/util.service';
// import { MessageMaker } from '../socket/socket.types';
// import { RestError } from '../rest/rest.types';
// import { StorageService } from '../storage/storage.service';
// import * as AuthActions from '../auth/store/auth.actions';
// import { User } from '../user/user.service';
// import { map } from 'rxjs/operators';

// @Injectable({
// 	providedIn: 'root',
// })
// export class SocketService {
// 	public socketAuthSubject: Subject<any> = new Subject<any>();
// 	public socketSubject: Subject<any> = new Subject<any>();
// 	public socketTypingSubject: Subject<any> = new Subject<any>();
// 	public socketReadSubject: Subject<any> = new Subject<any>();
// 	private chatServiceSubject = new Subject<any>();
// 	public chatServiceObservable = this.chatServiceSubject.asObservable();

// 	private messageUpdateSubject = new Subject<any>();
// 	public messageUpdateObservable = this.messageUpdateSubject.asObservable();

// 	private socket: any;
// 	private awaitingEvent: any = null;
// 	private awaitingResolve: any;
// 	private filter: any;

// 	private _token: Token | null = null;
// 	private _tokenStorageSubscription: Subscription;
// 	private storeSubscription: Subscription;
// 	private refreshing = false;

// 	private createThreadResolve: any;
// 	private awaitingThreadCreateReceiverPk: string;

// 	private createMessageResolve: any;
// 	private awaitingThreadMessageSend: string;
// 	private awaitingMessageSend: string;
// 	private awaitingMessageSendPk: string;
// 	private _user: User;

// 	constructor(
// 		private restService: RestService,
// 		private store: Store<fromApp.AppState>,
// 		private util: UtilService,
// 		private storageService: StorageService
// 	) {
// 		this.startAuthSubscription();
// 		this.initializeUserSubscription();
// 		console.log('socket service constructor');
// 	}

// 	public get token(): Token | null {
// 		return this._token;
// 	}

// 	public set token(token: Token | null) {
// 		this._token = token;
// 	}

// 	private initializeUserSubscription() {
// 		this.store
// 		  .select('user')
// 		  .pipe(map((e) => e.user))
// 		  .subscribe((user) => {
// 			if (user) {
// 			  this._user = user;
// 			}
// 		  });
// 	  }

// 	  private isMine(pk: string): boolean {
// 		return pk === this._user.pk;
// 	  }

// 	public init() {
// 		console.log('~~~~~~ REQUESTED CHAT SOCKET CONNECTION ~~~~~~~~');

// 		if (!this.filter) {
// 			const FILTER = require('bad-words');
// 			this.filter = new FILTER();
// 			this.prepareBadWords();
// 		}

// 		if (!this.token) {
// 			console.warn('TOKEN NOT SET!');
// 			return;
// 		}

// 		if (this.socket) {
// 			this.socket.reconnect(1, 'onLogin');
// 		} else {
// 			this.socket = new ReconnectingWebSocket(this.urlProvider, [], {
// 				connectionTimeout: 5000,
// 				reconnectionDelayGrowFactor: 1.2,
// 				minReconnectionDelay: 1,
// 			});
// 			this.socket.onerror = (e: any) => {
// 				console.log('Chat socket ERROR!');
// 				console.log(e);
// 			};

// 			this.socket.onclose = (e: any) => {
// 				console.error('Chat socket closed!');
// 				/*
//                     TODO: flip a flag telling us to re-query the chat messages (if we do connect again)
//                         to catch any messages that came thru while socket was refreshing.

//                         if internet is fast, usually only miss typing indicators,
//                         but if slow, could miss messages.
//                 */
// 				console.log(e);
// 			};

// 			this.socket.onopen = (e: any) => {
// 				console.log('%c🚀 Chat socket opened! Hooray! 💬', 'font-size:1.2rem; padding:0.4rem;');
// 				if (this.awaitingEvent) {
// 					this.awaitingEvent().then(() => {
// 						console.log('finished the awaiting event!');
// 						this.awaitingEvent = null;
// 					});
// 				}
// 			};

// 			this.socket.onmessage = (e: any) => {
// 				console.log('onmessage!');
// 				const response = JSON.parse(e.data);
// 				// console.log(response);
// 				this.socketPayloadType(response);
// 			};

// 			console.log('finished init for chat socket');
// 			console.log(this.urlProvider());
// 		}
// 	}

// 	public startAuthSubscription() {
// 		console.log('startAuthSubscription()');

// 		this.storeSubscription = this.store.select('auth').subscribe((authState) => {
// 			console.log('socket service storeSubscription:');
// 			console.log(JSON.stringify(authState, null, 2));

// 			if (this.token && authState.access && this.token.access && authState.access !== this.token.access) {
// 				// eslint-disable-next-line max-len
// 				console.log(
// 					'%csocket received a NeW tOkEn!',
// 					'font-size:1.6rem padding:0.5rem; background: white;color:red; font-family:"Tahoma", sans-serif;'
// 				);
// 				this.token = TokenMaker.create(authState);

// 				// manually tell socket to reconnect? - yes
// 				// should we re-query for messages we missed? - yes

// 				if (this.refreshing) {
// 					// we called for the refresh! now handle it
// 					this.refreshing = false;
// 					if (this.awaitingEvent) {
// 						this.awaitingEvent().then(() => {
// 							console.log('finished the awaiting event!');
// 							this.awaitingEvent = null;
// 						});
// 					}
// 				}
// 			}
// 			if (this.token == null && authState.access && authState.refresh) {
// 				this.token = TokenMaker.create(authState);
// 				console.log('+-+-+- +-+-+- +-+-+- +-+-+- +-+-+- +-+-+- ');
// 				console.log('+-+-+- set token on socket service +-+-+- ');
// 				console.log('+-+-+- +-+-+- +-+-+- +-+-+- +-+-+- +-+-+- ');
// 				console.log(this.token);
// 			} else if (this.token !== null && !authState.access && !authState.refresh) {
// 				this.token = null;
// 			}

// 			//   if(this.refreshing && this._token != null && authState && authState.access && authState.refresh){
// 			//     console.log('socket service recieved refreshed token!');
// 			//     this.refreshing = false;
// 			//     console.log(this._token);

// 			//     if(this.awaitingEvent){
// 			//       this.awaitingEvent()
// 			//       .then(()=> {
// 			//         console.log('finished the awaiting event!');
// 			//         this.awaitingEvent = null;
// 			//       });
// 			//     }

// 			//   }
// 		});
// 	}

// 	public onNewThreadCreated(thread: any): void {
// 		this.chatServiceSubject.next(thread);
// 	}

// 	send(data: any): Promise<any> {
// 		// console.log('send()');
// 		// console.log(data);
// 		/*
//         TODO: if token needs renewing, do it here
//         1.) renew token (observable)
//         2.) update connection to chat socket using new token (promise)
//         3.) send the message(s)
//         */

// 		return new Promise<void>((resolve, reject) => {
// 			if (!this._token) {
// 				console.log('no token is set! cannot send via socket.');
// 				reject();
// 			}

// 			if (this.refreshing) {
// 				console.log('is already refreshing, cannot start again!!');
// 				reject();
// 			}

// 			// ensure token has not already expired
// 			if (this._token && this.util.tokenExpired(this._token.access) && this._token.refresh) {
// 				console.log('need to renew the token before continuing with socket');

// 				// renew token

// 				this.refreshing = true;
// 				this.awaitingEvent = () => this.send(data);
// 				this.socketAuthSubject.next();
// 			} else {
// 				this.socket.send(JSON.stringify(data));
// 				resolve();
// 			}
// 		});
// 	}

// 	/* Called from new-message.page.ts */
// 	public sendMessageWithConfirmation(message: string, threadPk: string, tempPk:string, listing?: any): Promise<any> {
// 		return new Promise((resolve) => {
// 			this.createMessageResolve = resolve;
// 			this.awaitingThreadMessageSend = threadPk;
// 			this.awaitingMessageSend = message;
// 			this.awaitingMessageSendPk = tempPk;
// 			console.log('sendMessage...');

// 			let payload: any = {
// 				type: 'chat.send_message',
// 				thread: threadPk,
// 				content: message,
// 			};

// 			if (listing) {
// 				payload = {
// 					...payload,
// 					data: {
// 						pk: listing.pk,
// 						type: listing.type,
// 					},
// 				};
// 			}

// 			this.send(payload);
// 		});
// 	}

// 	sendTyping(threadPk: string): Promise<any> {
// 		return this.send({
// 			type: 'chat.send_typing',
// 			thread: threadPk,
// 		});
// 	}

// 	sendRead(threadPk: string, messagePk: string): Promise<any> {
// 		return this.send({
// 			type: 'chat.send_message_read',
// 			thread: threadPk,
// 			message: messagePk,
// 		});
// 	}

// 	createThread(receiver: any, message: string, listing: any): Promise<any> {

// 	    return new Promise((resolve)=> {

// 	      this.createThreadResolve = resolve;
// 	      this.awaitingThreadCreateReceiverPk = receiver.pk;
// 	      this.send({
// 	        type: 'chat.create_thread',
// 	        ['other_participants']: [
// 	          receiver.pk
// 	        ]
// 	        // data: {}
// 	      });
// 	    });

// 	  }

// 	/*
//     {
//     "pk": "ee37d721-9793-43ca-80d4-56d00f63b179",
//     "other_participants": [
//       {
//         "pk": "cba20fe2-08ff-47ec-b351-788ae62310fc",
//         "first_name": "Polly",
//         "last_name": "Pocket",
//         "avatar": "",
//         "is_staff": false
//       }
//     ],
//     "latest_message": null,
//     "latest_read": [],
//     "unread_count": 0,
//     "data": {},
//     "archived": false
//   }
//     */

// 	//   public createNewThread(msg: string): Observable<any> {
// 	// 	thread_pk: thread['pk'],
// 	// 	receiver: this.listing.owner_nested,
// 	// 	user: this._user,
// 	// 	thread: thread
// 	// 	return this.restService.post( environment.base_api_url + '/chat/' );
// 	//   }

// 	// public createThread(receiver: any, message: string, listing: any): Observable<any> {
// 	// 	return this.restService.post(environment.base_api_url + '/chat/', {
// 	// 		['other_participants']: [receiver.pk],
// 	// 		// data: ref
// 	// 	});
// 		// .subscribe( (data)=> {
// 		// 	const newThreadObject = MessageMaker.createThread({
// 		// 		pk: data.pk,
// 		// 		['other_participants']: [receiver],
// 		// 		['latest_read']: null,
// 		// 		['latest_message']: null,
// 		// 		['unread_count']: 0
// 		// 	});
// 		// 	// this.newThreadCreated = newThreadObject;
// 		// 	// this.state.results.unshift(newThreadObject)
// 		// 	this.sendMessage(message, data.pk, listing);
// 		// });
// 	// }

// 	stopSocket(reason: string = 'stopSocket') {
// 		if (this.socket) {
// 			this.socket.close(4011, reason);
// 		}
// 	}

// 	public cleanText(text) {
// 		if (this.filter) {
// 			return this.filter.clean(text);
// 		}
// 		return false;
// 	}

// 	private socketPayloadType(response: any) {
// 		const type = response.type;
// 		if (!type) {
// 			return false;
// 		}

// 		switch (type) {
// 			case 'auth.invalid_user':
// 				this.handleInvalidAuth(response); // both cases should invoke renewal attempt
// 				break;
// 			case 'chat.invalid_token':
// 				this.handleInvalidAuth(response); // both cases should invoke renewal attempt
// 				break;
// 			case 'chat.message':
// 				this.handleSocketMessage(response);
// 				break;
// 			case 'chat.message_read':
// 				this.handleSocketMessageRead(response);
// 				break;
// 			case 'chat.typing':
// 				this.handleSocketTyping(response);
// 				break;
// 			default:
// 				break;
// 		}
// 	}

// 	private urlProvider = (): string => {
// 		// const token = await getSessionToken();
// 		const token = this.token;
// 		// return `${environment.websocket_local}?token=${token?.access}`;
// 		return `${environment.websocket_url2}?token=${token?.access}`;
// 	};

// 	/*

// 		What are the chat thread socket events we should update
//         the thread view with?

//         user_typing (any thread)
//         latest_message (any thread) (place thread at the top)
//         message_read (any thread) (could be us reading or them - update last_read)
//             new thread is created (stack to top) (reset pagination / refresh?)

//     */

// 	private handleSocketMessage(response: any) {
//     if (this.isMessageWeLastSent(response)) {
//         if (this.createMessageResolve) {
// 			if(this.awaitingMessageSendPk) {
// 				this.messageUpdateSubject.next({ tempPk: this.awaitingMessageSendPk, updatedMessage: response });
// 			}
//             this.createMessageResolve(response);
//             this.createMessageResolve = null;
//             return; // Avoid emitting to socketSubject to prevent duplicates
//         }
//     }

//     // Emit the message for other listeners
//     this.socketSubject.next(response);
// }

// 	private handleSocketMessageRead(response: any) {
// 		this.socketReadSubject.next(response);
// 	}

// 	private handleSocketTyping(response: any) {
// 		if(!this.isMine(response)) {
// 			this.socketTypingSubject.next(response);
// 		}
// 	}

// 	private handleInvalidAuth(response: any) {
// 		console.log('handleInvalidAuth()', 'font-size:1.2rem; padding:0.5rem;');
// 		console.log(response);

// 		/* prevent socket from requesting multiple refreshes and burning a token */

// 		if (!this.token || !this.token.refresh) {
// 			console.log('no token for socket to refresh with');
// 			console.log(response);
// 			this.stopSocket('No auth token');
// 			return false;
// 		}
// 		this.socketAuthSubject.next(); // tell auth service to attempt renewal
// 	}

// 	private isMessageWeLastSent(response) {
// 		if (response && response.thread === this.awaitingThreadMessageSend && response.content === this.awaitingMessageSend) {
// 			this.awaitingThreadMessageSend = null;
// 			this.awaitingMessageSend = null;
// 			return true;
// 		}
// 		return false;
// 	}

// 	private isThreadWeLastCreated(response): boolean {
// 		const receiverPk = response.other_participants[0];
// 		if (receiverPk && receiverPk === this.awaitingThreadCreateReceiverPk) {
// 			this.awaitingThreadCreateReceiverPk = null;
// 			return true;
// 		}
// 		return false;
// 	}

// 	private prepareBadWords() {
// 		this.filter.removeWords('semen', 'poop');
// 	}
// }
