import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { RestService } from '../rest/rest.service';
import { Store } from '@ngrx/store';
import * as fromApp from '../../app.reducer';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { SocketService } from '../socket/socket.service';
import { User, UserService } from '../user/user.service';
import { PushService } from '../push/push.service';
import { ToastService } from '../toast/toast.service';
import { Router } from '@angular/router';

@Injectable({
	providedIn: 'root',
})
export class ChatService {
	public items: BehaviorSubject<any[]> = new BehaviorSubject<any[]>([]);
	public messages: BehaviorSubject<any[]> = new BehaviorSubject<any[]>([]);
	public typingIndicatorDelay = 1500;

	private _totalItems: any[] = [];
	private _user: User;

	private unreadCountSubject = new BehaviorSubject<number>(0);
	public unreadCount$ = this.unreadCountSubject.asObservable();

	constructor(
		private restService: RestService,
		private store: Store<fromApp.AppState>,
		private socketService: SocketService,
		private userService: UserService,
		private pushService: PushService,
		private toastService: ToastService,
		private router: Router
	) {
		this.initializeUserSubscription();
		this.initializeSocketSubscriptions();
		this.socketService.chatServiceObservable.subscribe((thread) => {
			this.addNewThread(thread);
			this.updateUnreadCount();
		});
	}

	private initializeUserSubscription() {
		this.store
			.select('user')
			.pipe(map((e) => e.user))
			.subscribe((user) => {
				if (user) {
					this._user = user;
					console.log('set user on chat Service');
				}
			});
	}

	private initializeSocketSubscriptions() {
		this.socketService.socketSubject.subscribe((message) => {
			this.handleNewMessage(message);
		});

		this.pushService.pushSubject.subscribe((message: any) => {
			if (message) this.handleNewMessage(message);
		});

		this.socketService.socketReadSubject.subscribe((readEvent) => {
			this.handleReadReceipt(readEvent);
		});
	}

	/*
		This exists to query the full list
		to know if users have engaged prior
		when submitting a new message.
		If they have, we direct them to the chat thread
		to signal they have history and it isn't new.

	*/
	public getAllThreads(): Observable<any> {
		console.log('getAllThreads()');
		return this.restService.get(`${environment.base_api_url}/chat/?page_size=-1`).pipe(
			map((response) => {
				console.log('getAllThreads response:');
				console.log(response);
				if (response?.length) {
					console.log('getAllThreads response.length:', response.length);
					this._totalItems = this.sortThreadsByActivity(response);
					this.updateUnreadCount();
					this.items.next(this._totalItems);
				}
				return response || [];
			})
		);
	}

	public getThreads(next?: string, refresh = false): Observable<any> {
		console.log('getThreads totalItems');
		console.log(this._totalItems);
		return this.restService.get(next || `${environment.base_api_url}/chat/`).pipe(
			map((response) => {
				console.log('getThreads:');
				console.log(response);
				if (response?.results?.length) {
					let merged = [...this._totalItems, ...response.results];
					if (refresh) {
						merged = [...response.results];
					}
					this._totalItems = this.sortThreadsByActivity(merged.filter((item, index, self) => index === self.findIndex((t) => t.pk === item.pk)));
					this.updateUnreadCount();
					this.items.next(this._totalItems);
				}
				return response;
			})
		);
	}

	public addNewThread(thread: any): void {
		console.log('addNewThread');
		console.log(thread);
		this._totalItems.unshift(thread);
		this.items.next(this._totalItems);
	}

	public addNewItem(item: any) {
		this._totalItems = [...this._totalItems, ...item];
		console.log('addNewItem:');
		console.log(item);
		this.items.next(this._totalItems);
	}

	private async handleNewMessage(message: any) {
		if (!this.isMessageFromCurrentUser(message)) {
			// @TODO: should consider whether we are in the chat view already, shouldnt show toast or incrememnt
			this.incrementUnreadCount();
			this.toastService.show(
				message.content,
				'top',
				() => {
					console.log('message clicked!');
					this.router.navigate(['/chat/' + message.thread]);
				},
				() => {
					console.log('on toast message dismiss...');
				}
			);
		}

		const threadIndex = this._totalItems.findIndex((t) => t.pk === message.thread);
		if (threadIndex !== -1) {
			console.log('we have a copy of this already!');
			console.log('threadIndex', threadIndex);
			const updatedThread = {
				...this._totalItems[threadIndex],
				latest_message: {
					pk: message.pk,
					created: message.created,
					content: message.content,
					data: message.data,
					sender: message.sender,
				},
				unread_count: this.isMessageFromCurrentUser(message)
					? this._totalItems[threadIndex].unread_count
					: this._totalItems[threadIndex].unread_count + 1,
				// Reset typing state when new message arrives
				isTyping: false,
				typingTimer: undefined,
			};

			// Clear any existing typing timer
			if (this._totalItems[threadIndex].typingTimer) {
				clearTimeout(this._totalItems[threadIndex].typingTimer);
			}

			// Remove thread from current position
			this._totalItems.splice(threadIndex, 1);
			// Add to beginning of array for most recent
			this._totalItems.unshift(updatedThread);

			console.log('this._totalItems[threadIndex]:');
			console.log(this._totalItems[threadIndex]);
			const targetItem = this._totalItems[threadIndex];
			if (targetItem.pk === message.thread) {
				// determine who is other_participant
				// compare against our pk, and request which userId
				if (targetItem.other_participants && typeof targetItem.other_participants[0] === 'string') {
					const isMine = targetItem.other_participants[0] === this.userService.user.pk;
					let targetPk = targetItem.other_participants[0];
					if (isMine) {
						targetPk = message.sender;
					}
					console.log('lets convert this into a user object with avatar and names');
					const response = await this.userService.getUserById(targetPk).toPromise();
					const otherUserDetails = {
						avatar: response.avatar,
						first_name: response.first_name,
						is_staff: response.is_staff,
						last_name: response.last_name,
						pk: response.pk,
					};
					console.log('getUserById response:');
					console.log(response);
					targetItem.other_participants[0] = otherUserDetails;
				}
			}

			this.items.next(this._totalItems);
		} else {
			// @TODO: full refresh of threads?
			// we would normally expect to receive a chat.thread event before
			console.log('We received a message from a user we have no history with!');
			console.log(message);
			// seeing the chat.message to follow
		}
	}

	private handleReadReceipt(readEvent: any) {
		const threadIndex = this._totalItems.findIndex((t) => t.pk === readEvent.thread);
		if (threadIndex !== -1) {
			const thread = this._totalItems[threadIndex];

			if (this.isCurrentUserMessage(readEvent)) {
				const updatedThread = {
					...thread,
					unread_count: 0,
					latest_read: thread.latest_read.map((read) => (read.user === this._user.pk ? { ...read, message: readEvent.message } : read)),
				};
				this._totalItems[threadIndex] = updatedThread;
				this.updateUnreadCount();
				this.items.next(this._totalItems);
			}
		}
	}

	private sortThreadsByActivity(threads: any[]): any[] {
		return threads.sort((a, b) => {
			// if (a.unread_count !== b.unread_count) {
			// 	return b.unread_count - a.unread_count;
			// }
			return new Date(b.latest_message?.created || 0).getTime() - new Date(a.latest_message?.created || 0).getTime();
		});
	}

	public getMessages(threadPk: string, next?: string): Observable<any> {
		return this.restService.get(next || `${environment.base_api_url}/chat/${threadPk}/messages/`);
	}

	private calculateUnreadCount(threads: any[]): number {
		return threads.reduce((total, thread) => total + (thread.unread_count || 0), 0);
	}

	private updateUnreadCount(): void {
		if (!this._totalItems || !this._totalItems.length) {
			return;
		}
		const unreadCount = this.calculateUnreadCount(this._totalItems);
		this.unreadCountSubject.next(unreadCount);
	}

	private incrementUnreadCount(): void {
		const currentCount = this.unreadCountSubject.value;
		this.unreadCountSubject.next(currentCount + 1);
	}

	private isMessageFromCurrentUser(message: any): boolean {
		return message.sender === this._user?.pk;
	}

	private isCurrentUserMessage(readEvent: any): boolean {
		return readEvent.sender === this._user?.pk;
	}

	public findUserThreadPk(userPk: string): string | null {
		const foundThread = this._totalItems.find((thread) => thread.other_participants.some((participant) => participant.pk === userPk));
		return foundThread ? foundThread.pk : null;
	}

	public clearThreads(): void {
		this._totalItems = [];
		this.unreadCountSubject.next(0);
		this.items.next([]);
	}

	public getUserThreadById(threadPk: string): Observable<any> {
		return this.restService.get(`${environment.base_api_url}/chat/${threadPk}/`);
	}
}
