import { Injectable } from '@angular/core';
import { ToastController } from '@ionic/angular';

@Injectable({
	providedIn: 'root',
})
export class ToastService {
	constructor(private toastCtrl: ToastController) {}

	async presentToast() {
		const toast = await this.toastCtrl.create({
			message: 'Your settings have been saved.',
			duration: 2000,
		});
		toast.present();
	}

	async presentToastWithOptions() {
		const toast = await this.toastCtrl.create({
			header: 'Toast header',
			message: 'Click to Close',
			position: 'top',
			buttons: [
				{
					side: 'start',
					icon: 'star',
					text: 'Favorite',
					handler: () => {
						console.log('Favorite clicked');
					},
				},
				{
					text: 'Done',
					role: 'cancel',
					handler: () => {
						console.log('Cancel clicked');
					},
				},
			],
		});
		await toast.present();

		const { role } = await toast.onDidDismiss();
		console.log('onDidDismiss resolved with role', role);
	}

	public async show(
		message: string,
		position: 'top' | 'bottom' | 'middle' = 'bottom',
		onClick?: () => void,
		onDismiss: () => void = () => {
			console.log('Toast dismissed.');
		},
		buttons: { text: string; handler?: () => void; role?: string }[] = []
	): Promise<void> {
		try {
			const prefersDark = window.matchMedia('(prefers-color-scheme: dark)').matches;
			let defaultButton = [
				{
					text: 'Dismiss',
					role: 'cancel',
				},
			];
			defaultButton = [];

			const toast = await this.toastCtrl.create({
				message: message,
				duration: 3000,
				position,
				translucent: true,
				buttons: buttons.length > 0 ? buttons : defaultButton,
				color: prefersDark ? 'dark' : 'light',
				cssClass: ['toast-custom', onClick ? 'clickable' : '', prefersDark ? 'toast-dark' : 'toast-light'].filter(Boolean),
			});

			if (onClick) {
				toast.addEventListener('click', (event: Event) => {
					console.log('CLICKED TOAST');
					const target = event.target as HTMLElement;
					if (!target.closest('ion-button')) {
						onClick();
						toast.dismiss();
					}
				});
			}

			toast.onWillDismiss().then(() => {
				onDismiss();
			});

			await toast.present();
		} catch (error) {
			console.error('Error showing toast:', error);
		}
	}
}
