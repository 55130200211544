import { Component, OnInit, ViewChild } from '@angular/core';
import { IonSlides, ModalController } from '@ionic/angular';
import { PushService } from '../../providers/push/push.service';

@Component({
	selector: 'app-tutorial',
	templateUrl: './tutorial.page.html',
	styleUrls: ['./tutorial.page.scss'],
})
export class TutorialPage implements OnInit {
	@ViewChild(IonSlides) slides: IonSlides;

	public slideData = [
		{
			title: 'Welcome to the Tutorial',
			description: 'Learn how to use our app with a quick guide.',
			buttonText: 'Continue',
		},
		{
			title: 'Feature Exploration',
			description: 'Discover the key features of our application.',
			buttonText: 'Continue',
		},
		{
			title: 'Get Started',
			description: "You're all set to begin your journey.",
			buttonText: "Let's go!",
		},
	];

	constructor(
		private modalController: ModalController,
		private pushService: PushService
	) {}

	ngOnInit() {
		console.log('tutorial init');
		console.log('user permission statuses?');
		const pushState = this.pushService.getPushState();
		console.log('pushState:');
		console.log(pushState);
	}

	requestPush() {
		this.pushService.requestPushPermission();
	}

	goToSlide(index: number) {
		this.slides.slideTo(index);
	}

	skipTutorial() {
		console.log('skipTutorial()');
		this.modalController.dismiss();
	}
}
