import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpErrorResponse, HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { catchError, switchMap, first } from 'rxjs/operators';
import { Observable, throwError, BehaviorSubject, of } from 'rxjs';
import { TokenManagerService } from '../auth/token-manager.service';
import { AuthService } from '../auth/auth.service';
import { environment } from '../../../environments/environment';
import { ToastService } from '../toast/toast.service';

@Injectable()
export class TokenInterceptor implements HttpInterceptor {
	private isRefreshing = false;
	private refreshTokenSubject: BehaviorSubject<string | null> = new BehaviorSubject<string | null>(null);

	constructor(
		private authService: AuthService,
		private toastService: ToastService,
		private tokenManager: TokenManagerService
	) {}

	intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
		// Bypass token addition for specific endpoints
		if (request.url.includes('/auth/jwt/refresh/') || request.url.includes('/auth/jwt/create/')) {
			request = this.setHeaders(request);
			return next.handle(request);
		}

		return this.tokenManager.getAccessToken().pipe(
			first(),
			switchMap((token: string | null) => {
				if (token) {
					request = request.clone({
						setHeaders: {
							Authorization: `JWT ${token}`,
							'Content-Type': 'application/json',
							Accept: `application/json; version=${environment.api_version}`,
						},
					});
				}

				return next.handle(request).pipe(
					catchError((error: HttpErrorResponse) => {
						if (error.status === 401) {
							return this.handle401Error(request, next);
						}
						return throwError(() => error);
					})
				);
			})
		);
	}

	private handle401Error(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
		if (this.isRefreshing) {
			return this.refreshTokenSubject.asObservable().pipe(
				switchMap((newToken: string | null) => {
					if (newToken) {
						request = request.clone({
							setHeaders: { Authorization: `JWT ${newToken}` },
						});
						return next.handle(request);
					}
					return throwError(() => new Error('Token refresh failed'));
				})
			);
		}

		this.isRefreshing = true;
		this.refreshTokenSubject.next(null);

		return this.tokenManager.refreshToken().pipe(
			switchMap((newToken: string) => {
				this.isRefreshing = false;
				this.refreshTokenSubject.next(newToken);
				request = request.clone({
					setHeaders: { Authorization: `JWT ${newToken}` },
				});
				return next.handle(request);
			}),
			catchError((err: any) => {
				this.isRefreshing = false;
				this.authService.logout();
				this.toastService.show('Session expired. Please log in again.');
				return throwError(() => err);
			})
		);
	}

	private setHeaders(request: HttpRequest<any>): HttpRequest<any> {
		return request.clone({
			headers: request.headers.set('Content-Type', 'application/json').set('Accept', `application/json; version=${environment.api_version}`),
		});
	}
}

// import { Injectable } from '@angular/core';
// import { HttpClient, HttpHeaders, HttpErrorResponse, HttpResponse, HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
// import { catchError, tap, switchMap, mergeMap, concatMap, exhaustMap, map, first } from 'rxjs/operators';
// import { Observable, throwError, of, Subscription } from 'rxjs';
// import { AuthService } from '../auth/auth.service';
// import { Token } from '../auth/auth.types';
// import { environment } from '../../../environments/environment';
// import { ToastService } from '../toast/toast.service';
// import { Store } from '@ngrx/store';
// import * as fromApp from '../../app.reducer';

// @Injectable()
// export class TokenInterceptor implements HttpInterceptor {
// 	storeSubscription: Subscription;
// 	lastToken = '';

// 	constructor(
// 		private authService: AuthService,
// 		private toastService: ToastService,
// 		private store: Store<fromApp.AppState>
// 	) {
// 		console.log('token interceptor constructor');
// 	}

// 	intercept(request: HttpRequest<any>, next: HttpHandler): Observable<any> {
// 		console.log('token-interceptor is listening...');

// 		/* Bypass token addition for renewals */
// 		if (request.url.includes('/auth/jwt/refresh/') || request.url.includes('/auth/jwt/create/')) {
// 			console.log('Bypassing token addition for refresh endpoint');
// 			// Keep original content-type and accept headers but don't add auth
// 			request = request.clone({
// 				headers: request.headers.set('Content-Type', 'application/json').set('Accept', 'application/json; version=' + environment.api_version),
// 			});
// 			return next.handle(request);
// 		}

// 		return this.store.select('auth').pipe(
// 			first(),
// 			mergeMap((token: Token | undefined) => {
// 				if (token && token.access) {
// 					if (token.access !== this.lastToken) {
// 						console.log('%cInterceptor USING NEW TOKEN', 'font-size: 1.6rem; color:green; background:black;');
// 					}

// 					console.log('adding token to request...');
// 					console.log(token.access);

// 					request = request.clone({
// 						headers: request.headers
// 							.set('Content-Type', 'application/json')
// 							.set('Accept', 'application/json; version=' + environment.api_version)
// 							.set('Authorization', 'JWT ' + token.access),
// 					});

// 					this.lastToken = token.access;
// 				} else {
// 					console.log('%cInterceptor NO TOKEN EMITTED', 'font-size: 1.2rem; color:yellow; background:black;');
// 					this.lastToken = '';
// 				}

// 				if (token.access !== this.lastToken) {
// 					console.log('%cInterceptor USING NEW TOKEN', 'font-size: 1.6rem; color:green; background:black;');
// 				}

// 				return next.handle(request).pipe(
// 					catchError((err: any): Observable<any> => {
// 						if (err instanceof HttpErrorResponse) {
// 							console.log('<<<<<<<<<<<<<<<<<<<<<<<->>>>>>>>>>>>>>>>>>>>>>>');
// 							console.log('<<<<<<<<<<<<<<< NETWORK ERROR >>>>>>>>>>>>>>>>>');
// 							console.log('<<<<<<<<<<<<<<<<<<<<<<<->>>>>>>>>>>>>>>>>>>>>>>');
// 							switch ((err as HttpErrorResponse).status) {
// 								case 400:
// 									return this.handle400Error(err);
// 								case 401:
// 									return this.handle401Error(err, request, next);
// 								default:
// 									return throwError(err);
// 							}
// 						}
// 					})
// 				);
// 			})
// 		);
// 	}

// 	handle400Error(error: HttpErrorResponse): Observable<any> {
// 		console.log('handle400Error()');
// 		console.log(error);
// 		return throwError(error);
// 	}

// 	handle401Error(error: HttpErrorResponse, request: HttpRequest<any>, next: HttpHandler): Observable<any | HttpEvent<any>> {
// 		// returned a 401 after including the auth token
// 		// check if the reason is due to bad auth token
// 		// if so, attempt renewal of auth.refresh
// 		// on failure, logout
// 		console.log('handle401Error()');
// 		console.log(error);
// 		console.log(error.error);

// 		// is the 401 due to auth token failure? (could also happen with resource missing?)
// 		// need to wrap following block with above logic.
// 		console.log('interceptor will try renewToken');
// 		if (!this.authService.token || !this.authService.token.refresh) {
// 			console.log('no refresh token is set!');
// 			// initial login hits this currently,
// 			// but that could be a direct call with unauth
// 			console.log(this.authService.token);
// 			return of(error);
// 		}

// 		return this.authService.renewToken().pipe(
// 			switchMap((data) => {
// 				console.log('renewToken data');
// 				console.log('NEW TOKEN ARRIVAL, applying to request....');
// 				console.log(data.access);

// 				if (data && data.access && data.refresh) {
// 					console.log('FIXED THE TOKEN!');
// 					console.log('returning cloned request with freshly returned token...');
// 					// request = request.clone({ headers: request.headers.set('Authorization', 'JWT ' + c.access ) });
// 					return next.handle(
// 						request.clone({
// 							setHeaders: { authorization: 'JWT ' + data.access },
// 						})
// 					);
// 				} else {
// 					console.log('we did NOT receive the token back');
// 					this.handle401Outcomes(error);
// 					return throwError({
// 						detail: 'Server connection failure',
// 						code: 'unknown_connection_failure',
// 					});
// 				}
// 			})
// 		);
// 	}

// 	handle401Outcomes(error: HttpErrorResponse) {
// 		let failCode = null;
// 		let failMessage = '';
// 		let failUrl = '';
// 		let errObj;
// 		if (error && error.error) {
// 			errObj = error.error;
// 			if (errObj.code) {
// 				failCode = errObj.code;
// 			}
// 			if (errObj.detail) {
// 				failMessage = errObj.detail;
// 			}
// 			if (error.url) {
// 				failUrl = error.url;
// 			}
// 		}

// 		if (failCode === 'token_not_valid') {
// 			console.log('failUrl:');
// 			console.log(failUrl);
// 			const pos = failUrl.indexOf('auth/jwt/refresh/');
// 			if (pos > -1) {
// 				console.log('this failure was the result of a tried refresh token attempt.');

// 				// should not do another refresh try.
// 				// clear the failed token from storage
// 				this.authService.logout();

// 				this.toastService.show('Failed to refresh session. Please login again.');

// 				return of(error);
// 			}
// 		}
// 	}
// }
