/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable no-trailing-spaces */
/* eslint-disable no-underscore-dangle */
import { Injectable } from '@angular/core';
import { BehaviorSubject, from, throwError } from 'rxjs';
import { RestService } from '../rest/rest.service';
import { environment } from '../../../environments/environment';
import { Observable } from 'rxjs';
import { tap, catchError, switchMap, map, withLatestFrom } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import * as fromApp from '../../app.reducer';
import * as AuthActions from './store/auth.actions';
import * as UserActions from '../user/store/user.actions';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { StorageService } from '../storage/storage.service';
import { Token, TokenMaker, UserLogin } from './auth.types';
import { SocketService } from '../socket/socket.service';
import { UtilService } from '../util/util.service';
import { AlertService } from '../alert/alert.service';
import { RestError } from '../rest/rest.types';

@Injectable({
	providedIn: 'root',
})
export class AuthService {
	private tokenRenewalUrl = environment.base_api_url + '/auth/jwt/refresh/';
	private reAuthUserUrl = environment.base_api_url + '/auth/jwt/create/';
	private storeSubscription: Subscription;
	private _token: Token;
	private _tokenStorageSubscription: Subscription;
	private _renewAuthFromSocketSubscription: Subscription;
	public isRefreshing = false;

	constructor(
		private restService: RestService,
		private store: Store<fromApp.AppState>,
		private router: Router,
		private storageService: StorageService,
		private socketService: SocketService,
		private util: UtilService,
		private alertService: AlertService
	) {
		this.init();
	}

	public get token() {
		return this._token;
	}

	public set token(token: Token | undefined) {
		/* saves token to storage via auth.effects.ts */
		this.store.dispatch(new AuthActions.ValidToken(token));
		this._token = token;
	}

	public generateAuthToken(data: UserLogin): Observable<any> {
		console.log('generateAuthToken data:');
		console.log(data);
		return this.restService.post(environment.base_api_url + '/auth/jwt/create/', data).pipe(
			catchError((err) => {
				/* @TODO: error messaging */
				console.log('an error happened');
				console.log(err);
				this.store.dispatch(new AuthActions.LoginFail(err));
				return err;
			}),
			map((e: Token | any) => this.handleTokenSuccess(TokenMaker.create(e)))
		);
	}

	public renewToken(storedRefresh?: string): Observable<any> {
		console.log('renewToken()');

		if (!this.token && !storedRefresh) {
			return throwError(() => new Error('No refresh token available'));
		}
		if (this.isRefreshing) {
			return throwError(() => new Error('Refresh currently in progress'));
		}
		this.isRefreshing = true;
		return this.restService.post(this.tokenRenewalUrl, { refresh: storedRefresh || this.token.refresh }).pipe(
			catchError((error: any) => {
				console.error('Error renewing auth token:');
				console.log(error);
				this.isRefreshing = false;
				if (error instanceof RestError) {
					return this.handleTokenError(error.error);
				}
				return this.handleTokenError(error);
			}),
			map((body) => this.handleTokenSuccess(TokenMaker.create(body))),
			tap(() => {
				this.isRefreshing = false;
			})
		);
	}

	public logout() {
		this.store.dispatch(new AuthActions.Logout());
		this.store.dispatch(new UserActions.Logout());
		this._reportLogoutToBackend();
	}

	private _reportLogoutToBackend() {
		console.log('_reportLogoutToBackend()');
		// Fern adding?
		// const logoutResponse = this.restService.get(environment.base_api_url + '/auth/jwt/clear').toPromise();
		// console.log('logoutResponse:');
		// console.log(logoutResponse);
	}

	private init() {
		this.startAuthSubscription();
		this.checkStorageForToken();
	}

	private startAuthSubscription() {
		this.storeSubscription = this.store.select('auth').subscribe((authState) => {
			if (this._token == null && authState.access && authState.refresh) {
				console.log('user logged in! Should get user & open socket');
				this._token = TokenMaker.create(authState);
				this.socketService.init();
				this.store.dispatch(new UserActions.GetUser());
				this.router.navigate(['/tabs']);
			} else if (this._token != null && !authState.access && !authState.refresh) {
				this.storageService.clearAuthStorage();
				this.socketService.stopSocket('onLogout');
				this._token = null;
				this.router.navigate(['/tabs']);
			}
		});

		/* Listen to token renewal requests from the Socket */
		/* Process the renewal & update ngrx auth store */
		this._renewAuthFromSocketSubscription = this.socketService.socketAuthSubject.subscribe(() => {
			console.log('chat socket requesting a renewal flow....');
			this.renewToken()
				.pipe(
					catchError((err: any): Observable<any> => {
						console.log('problem renewing token from chat!');
						console.log(err);
						return err;
					})
				)
				.subscribe((response) => {
					console.log('renewToken response after socket requested it');
					console.log(response);
					this.storageService.setToken(TokenMaker.create(response));

					/* need to refresh tab2 chat threads */
				});
		});
	}

	private checkStorageForToken() {
		this._tokenStorageSubscription = this.storageService.storedTokenSubject.subscribe((data) => {
			if (typeof data === 'undefined') {
				return;
			}
			if (data === null) {
				console.log('No stored token exists..');
				return;
			}

			if (data instanceof Token) {
				const t = data.access;
				const r = data.refresh;
				if (this.util.tokenExpired(t)) {
					console.log('token is expired!!!!');
					this.renewToken(r).subscribe((e) => {
						console.log('completed checkStorageForToken renewAuthToken');
					});
				} else {
					console.log('token is valid!!!');
					this.handleTokenSuccess(data);
				}
			}
		});
	}

	private handleTokenError(error: any): Observable<never> {
		console.error('Error renewing auth token:');
		if (error) {
			console.log(error);
			this.alertService.presentFailedUpdateAlert(error);
		}
		return throwError('An error occurred while renewing the auth token.');
	}

	private handleTokenSuccess(e: Token): Token {
		console.log('handleTokenSuccess()');
		console.log(e);
		if (e && e.access) {
			this.token = e as Token; /* this setter dispatches ValidToken() */
			console.log('access', e.access);
			console.log('refresh', e.refresh);
		} else {
			this.token = undefined;
		}
		return e as Token;
	}
}
