import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpHeaders, HttpRequest, HttpHandler, HttpEvent, HttpResponse } from '@angular/common/http';
import { Observable, from, throwError } from 'rxjs';
import { Platform } from '@ionic/angular';
import { HTTP } from '@ionic-native/http/ngx';
import { environment } from '../../../environments/environment';
import { Store } from '@ngrx/store';
import * as fromApp from '../../app.reducer';
import { Subscription } from 'rxjs';
import { Token } from '../../providers/auth/auth.types';
import { AuthService } from '../../providers/auth/auth.service';

type HttpMethod = 'get' | 'post' | 'put' | 'patch' | 'head' | 'delete' | 'upload' | 'download';

@Injectable()
export class NativeHttpInterceptor implements HttpInterceptor {
  private readonly TIMEOUT = 30000;
  private _tokenSubscription: Subscription;

  constructor(
    private nativeHttp: HTTP,
    private authService: AuthService,
    private platform: Platform,
    private store: Store<fromApp.AppState>
  ) {
    console.log('[NativeHttpInterceptor] Initialized');
  }

  public intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (!this.platform.is('capacitor') || request.url.includes('api.mapbox.com')) {
      console.log('[NativeHttpInterceptor] Skipping interception for:', request.url);
      return next.handle(request);
    }

    console.log('[NativeHttpInterceptor] Intercepting request:', request.url);
    return from(this.handleNativeRequest(request));
  }

  private async _nativeHeaders(): Promise<Record<string, string>> {
    const baseHeaders = {
      'Content-Type': 'application/json',
      Accept: `application/json; version=${environment.api_version}`,
    };

    if (this.authService.token?.access) {
      const token = this.authService.token.access;
      console.log('[NativeHttpInterceptor] Token being used:', token.slice(0, 10) + '...');
      
      // Using JWT prefix to match the non-native interceptor
      return {
        ...baseHeaders,
        Authorization: `JWT ${token}`,
      };
    }

    console.log('[NativeHttpInterceptor] No auth token available');
    return baseHeaders;
  }

  private async handleNativeRequest(request: HttpRequest<any>): Promise<HttpResponse<any>> {
    console.log('[NativeHttpInterceptor] Processing request:', request.url);

    try {
      await this.platform.ready();
      
      const method = request.method.toLowerCase() as HttpMethod;
      const headers = await this._nativeHeaders();

      console.log('[NativeHttpInterceptor] Sending request:', { 
        url: request.url, 
        method, 
        headers,
        body: request.body 
      });

      const nativeHttpResponse = await this.nativeHttp.sendRequest(request.url, {
        method,
        data: request.body ?? {},
        headers,
        timeout: this.TIMEOUT,
        responseType: 'json',
        serializer: 'json',
      });

      console.log('[NativeHttpInterceptor] Response received:', {
        status: nativeHttpResponse.status,
        headers: nativeHttpResponse.headers,
        data: nativeHttpResponse.data
      });

      return new HttpResponse({
        body: nativeHttpResponse.data,
        status: nativeHttpResponse.status,
        headers: new HttpHeaders(nativeHttpResponse.headers),
        url: nativeHttpResponse.url,
      });

    } catch (error) {
      console.error('[NativeHttpInterceptor] Request failed:', {
        status: error.status,
        headers: error.headers,
        error: error.error,
        message: error.message
      });
      
      let parsedError = null;
      try {
        parsedError = error.error ? JSON.parse(error.error) : error;
      } catch (e) {
        parsedError = error.error || error;
      }

      if (error.status) {
        return Promise.reject(
          new HttpResponse({
            body: parsedError,
            status: error.status,
            headers: new HttpHeaders(error.headers ?? {}),
            url: error.url ?? request.url,
          })
        );
      }

      return Promise.reject({
        body: parsedError,
        status: 0,
        url: request.url
      });
    }
  }
}

// import { Injectable } from '@angular/core';
// import { HttpInterceptor, HttpHeaders, HttpRequest, HttpHandler, HttpEvent, HttpResponse } from '@angular/common/http';
// import { Observable, from, throwError } from 'rxjs';
// import { Platform } from '@ionic/angular';
// import { HTTP } from '@ionic-native/http/ngx';
// import { environment } from '../../../environments/environment';
// import { Store } from '@ngrx/store';
// import * as fromApp from '../../app.reducer';
// import { Subscription } from 'rxjs';
// import { Token } from '../../providers/auth/auth.types';
// import { AuthService } from '../../providers/auth/auth.service';

// type HttpMethod = 'get' | 'post' | 'put' | 'patch' | 'head' | 'delete' | 'upload' | 'download';

// @Injectable()
// export class NativeHttpInterceptor implements HttpInterceptor {
// 	private _tokenSubscription: Subscription;

// 	constructor(
// 		private nativeHttp: HTTP,
// 		private authService: AuthService,
// 		private platform: Platform,
// 		private store: Store<fromApp.AppState>
// 	) {}

// 	public intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
// 		if (!this.platform.is('capacitor') || request.url.includes('api.mapbox.com')) {
// 			return next.handle(request);
// 		}

// 		console.log('native interceptor is listening!!');
// 		console.log(request.url);
// 		return from(this.handleNativeRequest(request));
// 	}

// 	private async _nativeHeaders(): Promise<any> {
// 		return new Promise((resolve) => {
// 			if (this.authService.token && this.authService.token['access']) {
// 				console.log('returning logged in headers');
// 				resolve({
// 					'Content-Type': 'application/json',
// 					Accept: 'application/json; version=' + environment.api_version,
// 					Authorization: 'JWT ' + this.authService.token['access'],
// 				});
// 			} else {
// 				console.log('returning NOT LOGGED IN headers');

// 				resolve({
// 					'Content-Type': 'application/json',
// 					Accept: 'application/json; version=' + environment.api_version,
// 				});
// 			}
// 		});
// 	}

// 	// private async handleNativeRequest(request: HttpRequest<any>): Promise<HttpResponse<any>> {
// 	// 	console.log('handleNativeRequest()');

// 	// 	const headerKeys = request.headers.keys();
// 	// 	const header = {};
// 	// 	let headers: any = [];
// 	// 	headerKeys.forEach((key) => {
// 	// 		headers[key] = request.headers.get(key);
// 	// 	});

// 	// 	try {
// 	// 		await this.platform.ready();

// 	// 		console.log('await platform ready');
// 	// 		const method = <HttpMethod>request.method.toLowerCase();

// 	// 		console.log('method', method);

// 	// 		const nativeHeaders = await this._nativeHeaders();

// 	// 		console.log('request.url');
// 	// 		console.log(request.url);

// 	// 		console.log('nativeHeaders:');
// 	// 		console.log(nativeHeaders);

// 	// 		const nativeHttpResponse = await this.nativeHttp
// 	// 			.sendRequest(request.url, {
// 	// 				method: method,
// 	// 				data: request.body ? request.body : {},
// 	// 				headers: nativeHeaders,
// 	// 				timeout: 5000,
// 	// 				responseType: 'json',
// 	// 				serializer: 'json',
// 	// 			})
// 	// 			.catch((err) => {
// 	// 				console.log('NATIVE INTERCEPTOR:  some native http error occured');
// 	// 				if (err) {
// 	// 					// alert(err);
// 	// 					console.error(err);
// 	// 				}
// 	// 				return throwError(err);
// 	// 			});

// 	// 		let body;

// 	// 		console.log('nativeHttpResponse:');
// 	// 		console.log(nativeHttpResponse);

// 	// 		const response = new HttpResponse({
// 	// 			body: nativeHttpResponse.data,
// 	// 			status: nativeHttpResponse.status,
// 	// 			headers: new HttpHeaders(nativeHttpResponse.headers),
// 	// 			url: nativeHttpResponse.url,
// 	// 		});

// 	// 		return Promise.resolve(response);
// 	// 	} catch (error) {
// 	// 		if (!error.status) {
// 	// 			return Promise.reject(error);
// 	// 		}

// 	// 		const response = new HttpResponse({
// 	// 			body: JSON.parse(error.error),
// 	// 			status: error.status,
// 	// 			headers: error.headers,
// 	// 			url: error.url,
// 	// 		});

// 	// 		return Promise.reject(response);
// 	// 	}
// 	// }

// 	private async handleNativeRequest(request: HttpRequest<any>): Promise<HttpResponse<any>> {
// 		console.log('handleNativeRequest()');
	  
// 		const headerKeys = request.headers.keys();
// 		const headers: Record<string, string> = {};
// 		headerKeys.forEach((key) => {
// 		  headers[key] = request.headers.get(key) ?? '';
// 		});
	  
// 		try {
// 		  await this.platform.ready();
// 		  console.log('Platform ready');
	  
// 		  const method = <HttpMethod>request.method.toLowerCase();
// 		  const headers = await this._nativeHeaders();
	  
// 		  console.log('Request:', { url: request.url, method, headers });
	  
// 		  const nativeHttpResponse = await this.nativeHttp.sendRequest(request.url, {
// 			method,
// 			data: request.body ?? {},
// 			headers,
// 			// timeout: 5000,
// 			responseType: 'json',
// 			serializer: 'json',
// 		  });
	  
// 		  console.log('Native HTTP Response:', nativeHttpResponse);
	  
// 		  return new HttpResponse({
// 			body: nativeHttpResponse.data,
// 			status: nativeHttpResponse.status,
// 			headers: new HttpHeaders(nativeHttpResponse.headers),
// 			url: nativeHttpResponse.url,
// 		  });
// 		} catch (error) {
// 		  console.error('Error during native HTTP request:', error);
// 			const endpointErrorMsg = JSON.parse(error.error);
// 			console.log(error.headers);
// 			console.log('endpointErrorMsg');
// 			console.log(endpointErrorMsg);

// 		  // Handle error with proper formatting
// 		  if (error.status) {
// 			return Promise.reject(
// 			  new HttpResponse({
// 				body: error.error ? JSON.parse(error.error) : null,
// 				status: error.status,
// 				headers: new HttpHeaders(error.headers ?? {}),
// 				url: error.url ?? request.url,
// 			  })
// 			);
// 		  }
	  
// 		  // Generic error fallback
// 		  return Promise.reject(error);
// 		}
// 	  }
	  
// }
