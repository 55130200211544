/* eslint-disable no-underscore-dangle */
import { Injectable } from '@angular/core';
import { Storage } from '@ionic/storage-angular';
import { BehaviorSubject } from 'rxjs';
import { Token, TokenMaker } from '../auth/auth.types';

@Injectable({
	providedIn: 'root',
})
export class StorageService {
	public storedTokenSubject = new BehaviorSubject<Token>(undefined);
	private _storage: Storage | null = null;
	private _loadResolve: any;
	private _init = false;
	public tokenKey = 'tokens';

	constructor(private storage: Storage) {
		this.init();
	}

	async init() {
		// If using, define drivers here: await this.storage.defineDriver(/*...*/);
		const storage = await this.storage.create();
		this._storage = storage;
		if (!this._init) {
			this._init = true;
			this.checkStoredToken();
		}
	}

	public setItem(key: string, value: any): Promise<any> {
		return this._storage?.set(key, value);
	}

	public setToken(value: any) {
		console.log(
			'%csetToken',
			'background:#0CB2AC;color:#F9F9ED;border-radius:1rem;padding:0.3rem;font-family:"Playfair Display", sans-serif; font-size: 1.2rem;'
		);
		this._storage?.set(this.tokenKey, value);
	}

	public getToken(key = this.tokenKey) {
		console.log(
			'%cgetToken',
			'background:#7D84B2;color:#D9DBF1;border-radius:1rem;padding:0.3rem;font-family:"Playfair Display", sans-serif; font-size: 1.2rem;'
		);
		return this._storage?.get(key);
	}

	public clear(key: string): Promise<any> {
		return this._storage?.remove(key);
	}

	public clearAuthStorage(): Promise<any> {
		console.log(
			'%cclearAuthStorage',
			'background:#F9F9ED;color:#0CB2AC;border-radius:1rem;padding:0.3rem;font-family:"Playfair Display", sans-serif; font-size: 1.2rem;'
		);
		return this.clear(this.tokenKey);
	}

	private checkStoredToken(): void {
		console.log('checkStoredToken()');
		this.getToken(this.tokenKey).then((data) => {
			console.log('checkStoredToken:');
			console.log(JSON.stringify(data, null, 2));
			if (data && data.access && data.refresh) {
				this.storedTokenSubject.next(TokenMaker.create(data));
			}
		});
	}
}
