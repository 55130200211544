import { Injectable } from '@angular/core';
import { Platform } from '@ionic/angular';
import { Router } from '@angular/router';
import OneSignal from 'onesignal-cordova-plugin';
import { BehaviorSubject } from 'rxjs';
import { AuthService } from '../auth/auth.service';
import { Store, select } from '@ngrx/store';
import { environment } from 'src/environments/environment';
import * as fromApp from '../../app.reducer';
import { getUser } from '../../providers/user/store/user.selectors';
import { User } from '../user/user.service';

@Injectable({
	providedIn: 'root',
})
export class PushService {
	public pushSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);
	private isOneSignalInitialized = false;
	private pendingNotification: any = null; // Store notification for post-auth processing
	private user: User;

	constructor(
		private platform: Platform,
		private router: Router,
		private authService: AuthService,
		private store: Store<fromApp.AppState>
	) {
		console.log('PushService constructor initialized.');

		this.platform.ready().then(() => {
			this.store.pipe(select(getUser)).subscribe((user: User) => {
				let shouldInit = false;
				if (user) {
					if (!this.user) {
						shouldInit = true;
					}
				}
				this.user = user;
				if (shouldInit) {
					this.initOneSignalForAuthenticatedUser();
				}
			});

			if (this.platform.is('capacitor')) {
				// Register for notification click events early
				this.registerNotificationClickHandler();

				// Check the push state on app initialization
				this.logPushState();
			}
		});
	}

	private registerNotificationClickHandler(): void {
		OneSignal.Notifications.addEventListener('click', async (event: any) => {
			const payload = event.notification.additionalData;
			console.log('Notification clicked:', payload);

			const threadId = payload?.thread_id;
			if (threadId) {
				const route = `/chat/${threadId}`;
				if (this.isAuthenticated()) {
					this.router.navigate([route]);
				} else {
					console.log('User not authenticated. Redirecting to login with intent.');
					this.pendingNotification = payload; // Save payload for post-login processing
					this.router.navigate(['/login'], { queryParams: { returnUrl: route } });
				}
			} else {
				console.warn('Notification missing threadId. Processing as general notification.');
				this.pushSubject.next(payload);
			}
		});
	}

	private async initOneSignalForAuthenticatedUser(): Promise<void> {
		console.log('initOneSignalForAuthenticatedUser()');

		if (!this.platform.is('capacitor') || this.isOneSignalInitialized) return;

		if (!this.user) {
			console.log('No user authenticated. Skipping OneSignal initialization.');
			return;
		}

		try {
			OneSignal.initialize(environment.one_signal.app_id);
			this.isOneSignalInitialized = true;
			console.log('OneSignal initialized with App ID:', environment.one_signal.app_id);

			this.logPushState();

			OneSignal.User.addTags({
				user_pk: this.user.pk,
				version: environment.version,
			});
			console.log('Authenticated user tagged in OneSignal.');
		} catch (error) {
			console.error('Error initializing OneSignal:', error);
		}
	}

	public getPushState() {
		const deviceState = OneSignal.User.pushSubscription;
		const onesignalId = OneSignal.User.getOnesignalId();
		return { deviceState, onesignalId };
	}

	public async logPushState(): Promise<void> {
		try {
			const subscriptionState = OneSignal.User.pushSubscription;
			const onesignalId = await OneSignal.User.getOnesignalId();

			console.log('OneSignal Push Subscription State:', subscriptionState);
			console.log('OneSignal Player ID:', onesignalId);
		} catch (error) {
			console.error('Error checking OneSignal push state:', error);
		}
	}

	public processPendingNotification(): void {
		if (!this.pendingNotification) return;

		const threadId = this.pendingNotification?.thread_id;
		if (threadId) {
			this.router.navigate([`/chat/${threadId}`]);
		}
		this.pendingNotification = null; // Clear after processing
	}

	private isAuthenticated(): boolean {
		console.log('isAuthenticated()', !!this.authService.token);
		return !!this.authService.token;
	}

	public async requestPushPermission(): Promise<void> {
		try {
			const permission = await OneSignal.Notifications.requestPermission();
			console.log('Push notification permission:', permission);
			if (permission) {
				await this.initOneSignalForAuthenticatedUser();
			}
		} catch (error) {
			console.error('Error requesting push notification permission:', error);
		}
	}
}

// import { Injectable } from '@angular/core';
// import { Capacitor } from '@capacitor/core';
// import { Platform } from '@ionic/angular';
// import { select, Store } from '@ngrx/store';
// import { environment } from 'src/environments/environment';
// import * as fromApp from '../../app.reducer';
// import { getUser } from '../../providers/user/store/user.selectors';
// import { User } from '../user/user.service';
// import OneSignal from 'onesignal-cordova-plugin';
// import { GeolocationService } from '../geolocation/geolocation.service';
// import { Storage } from '@ionic/storage-angular';
// import { ChatService } from '../chat/chat.service';
// import { BehaviorSubject } from 'rxjs';
// import { AuthService } from '../auth/auth.service';
// import { Router } from '@angular/router';

// @Injectable({
// 	providedIn: 'root',
// })
// export class PushService {
// 	public pushSubject: BehaviorSubject<any[]> = new BehaviorSubject<any[]>([]);
// 	public pushMessages$ = this.pushSubject.asObservable();
// 	private user: User;
// 	private _currentLocation: any;

// 	constructor(
// 		private store: Store<fromApp.AppState>,
// 		private platform: Platform,
// 		private geolocationService: GeolocationService,
// 		private storage: Storage,
// 		private authService: AuthService,
// 		private router: Router
// 	) {
// 		console.log('PushService constructor');
// 		this.platform.ready().then(() => {
// 			this.store.pipe(select(getUser)).subscribe((user: User) => {
// 				let shouldInit = false;
// 				if (user) {
// 					if (!this.user) {
// 						shouldInit = true;
// 					}
// 				}
// 				this.user = user;
// 				if (shouldInit) {
// 					this.initOneSignal();
// 				}
// 			});
// 		});
// 	}

// 	getPushState() {
// 		const deviceState = OneSignal.User.pushSubscription;
// 		const onesignalId = OneSignal.User.getOnesignalId();
// 		console.log('OneSignal deviceState:', deviceState);
// 		console.log('OneSignal onesignalId:', onesignalId);
// 		return deviceState;
// 	}

// 	async initOneSignal(): Promise<void> {
// 		console.log('OneSignalInit()');
// 		const isNative = Capacitor.isNativePlatform();
// 		console.log('Capacitor isNative', isNative, 'Platform:', this.platform.platforms());

// 		if (this.platform.is('capacitor')) {
// 			try {
// 				console.log('Is capacitor!');

// 				// Log more platform info
// 				console.log('Platform details:', {
// 					ios: this.platform.is('ios'),
// 					android: this.platform.is('android'),
// 					platforms: this.platform.platforms(),
// 					cordova: typeof (window as any).cordova !== 'undefined',
// 				});

// 				// Check if OneSignal is available
// 				if (!OneSignal) {
// 					throw new Error('OneSignal not found');
// 				}

// 				OneSignal.Debug.setLogLevel(6);
// 				console.log('Debug level set');

// 				try {
// 					OneSignal.initialize(environment.one_signal.app_id);
// 					console.log('OneSignal initialized');
// 					this.sendInitTags();
// 				} catch (initError) {
// 					console.error('OneSignal init specific error:', initError);
// 					throw initError;
// 				}

// 				try {
// 					// (OneSignal as any).setNotificationOpenedHandler((openResult: any) => {
// 					// 	console.log('Notification clicked:');
// 					// 	console.log(openResult);
// 					// 	// Handle notification click logic here
// 					// });
// 					OneSignal.Notifications.addEventListener('click', async (e) => {
// 						let noticePayload = e.notification;
// 						console.log('+-+-+ Notification Clicked : ');
// 						console.log(noticePayload);

// 						const additionalData: any = noticePayload.additionalData || null;
// 						if (!additionalData) {
// 							return;
// 						}

// 						const threadId = additionalData.thread_id;
// 						const messageId = additionalData.message_id;

// 						console.log('PROCESS MESSAGE:');
// 						console.log(additionalData);
// 						console.log(messageId);
// 						console.log(threadId);

// 						this.pushSubject.next(additionalData);

// 						/*
// 						Actual Prod message looks like:
// 						{
// 							"body": "Hiya!!! test this **** out!",
// 							"templateName": "message_received",
// 							"category": "message",
// 							"sound": "default",
// 							"templateId": "8fdf1b47-e740-4d6b-83cf-92b33c28d740",
// 							"additionalData": {
// 								"created": "2024-12-19T18:51:28-0600",
// 								"content": "Hiya!!! test this **** out!",
// 								"message_id": "64061dbd-9985-4927-b898-d79004e2e05b",
// 								"thread_id": "4e1450b5-982d-4df0-9ae7-7f3d53b829c7"
// 							},
// 							"notificationId": "f5392f82-a6a2-4398-80da-71838f72f1f2",
// 							"rawPayload": {
// 								"aps": {
// 								"category": "message",
// 								"badge": 1,
// 								"sound": "default",
// 								"interruption-level": "active",
// 								"alert": {
// 									"title": "tester_06067 user",
// 									"body": "Hiya!!! test this **** out!"
// 								},
// 								"mutable-content": 1,
// 								"relevance-score": 0
// 								},
// 								"custom": {
// 								"u": "",
// 								"badge_inc": 1,
// 								"ti": "8fdf1b47-e740-4d6b-83cf-92b33c28d740",
// 								"i": "f5392f82-a6a2-4398-80da-71838f72f1f2",
// 								"tn": "message_received",
// 								"a": {
// 									"created": "2024-12-19T18:51:28-0600",
// 									"content": "Hiya!!! test this **** out!",
// 									"message_id": "64061dbd-9985-4927-b898-d79004e2e05b",
// 									"thread_id": "4e1450b5-982d-4df0-9ae7-7f3d53b829c7"
// 								},
// 								"collapse_id": "4DD1FF0D-95D4-4E7B-8A33-636C9187F234"
// 								}
// 							},
// 							"title": "tester_06067 user",
// 							"badge": 1,
// 							"mutableContent": true,
// 							"actionButtons": [],
// 							"badgeIncrement": 1,
// 							"interruptionLevel": "active",
// 							"launchURL": "",
// 							"relevanceScore": 0,
// 							"collapseId": "4DD1FF0D-95D4-4E7B-8A33-636C9187F234"
// 						}

// 						Test Message from OneSignal Dashboard looks like:
// 						{
// 							"body": "Giggidddy giggggidddyyyy",
// 							"subtitle": "Test of the system",
// 							"sound": "default",
// 							"additionalData": {},
// 							"notificationId": "c47c6942-da62-4eff-9392-8e4321bea1cd",
// 							"rawPayload": {
// 								"aps": {
// 								"badge": 1,
// 								"mutable-content": 1,
// 								"alert": {
// 									"subtitle": "Test of the system",
// 									"title": "Message Test 0004",
// 									"body": "Giggidddy giggggidddyyyy"
// 								},
// 								"relevance-score": 0,
// 								"interruption-level": "active",
// 								"sound": "default"
// 								},
// 								"custom": {
// 								"i": "c47c6942-da62-4eff-9392-8e4321bea1cd",
// 								"badge_inc": 1,
// 								"a": {},
// 								"collapse_id": "F09168DD-BBE7-4F54-B365-D64D7782102A"
// 								}
// 							},
// 							"badge": 1,
// 							"title": "Message Test 0004",
// 							"mutableContent": true,
// 							"badgeIncrement": 1,
// 							"actionButtons": [],
// 							"interruptionLevel": "active",
// 							"relevanceScore": 0,
// 							"collapseId": "F09168DD-BBE7-4F54-B365-D64D7782102A"
// 							}
// 						*/
// 					});
// 					console.log('Notification opened handler set');

// 					// OneSignal.Notifications.addEventListener('foregroundWillDisplay', this.onNotificationReceived.bind(this));
// 				} catch (listenerError) {
// 					console.error('Error setting click listener:', listenerError);
// 				}

// 				try {
// 					const permission = await OneSignal.Notifications.requestPermission();
// 					console.log('Permission result:', permission);
// 				} catch (permError) {
// 					console.error('Error requesting permission:', permError);
// 				}
// 			} catch (error) {
// 				console.error('Error in push setup:', error);
// 				if (error instanceof Error) {
// 					console.error('Error message:', error.message);
// 					console.error('Stack:', error.stack);
// 				}
// 			}
// 		}
// 	}

// 	async handleColdStart() {
// 		console.log('handleColdStart()');
// 		const pushState = this.getPushState();
// 		console.log('pushState:');
// 		console.log(pushState);
// 		const lastNotification = await this.storage.get('lastNotification');
// 		if (lastNotification) {
// 			await this.processNotification(lastNotification);
// 			await this.storage.remove('lastNotification');
// 		}
// 	}

// 	getRouteFromNotification(notification: any): string {
// 		console.log('getRouteFromNotification()');
// 		const additionalData = notification.additionalData;
// 		if (additionalData.thread_id) {
// 			return `/messages/${additionalData.thread_id}`;
// 		}
// 		// if (additionalData.category === 'message') {
// 		// 	return '/inbox';
// 		// }
// 		return '/home';
// 	}

// 	async processNotification(notification: any): Promise<void> {
// 		console.log('processNotification()');
// 		console.log(notification);
// 		// return new Promise((resolve) => {
// 		// 	resolve();
// 		// });

// 		/* what to do with this cold start situation? */
// 		const isAuthenticated = !!this.authService.token;
// 		if (!isAuthenticated) {
// 			await this.router.navigate(['/login'], { queryParams: { returnUrl: this.getRouteFromNotification(notification) } });
// 			return;
// 		}

// 		const route = this.getRouteFromNotification(notification);
// 		await this.router.navigate([route]);
// 	}

// 	sendInitTags() {
// 		(OneSignal as any)?.User?.addTags({
// 			version: environment.version,
// 			user_pk: this.user.pk,
// 		});

// 		// if (this._currentLocation) {
// 		// 	console.log('this._currentLocation');
// 		// 	console.log(this._currentLocation);
// 		// 	console.log('this.user');
// 		// 	console.log(this.user);
// 		// 	(OneSignal.User as any).setLocation(this._currentLocation.latitude, this._currentLocation.longitude);
// 		// } else {
// 		// 	console.log('NO coords available');
// 		// }
// 	}
// }
