export interface RangeFilter {
	key: string;
	type: string;
	label: string;
	min?: number;
	max?: number;
	step?: number | ((lower: number, upper: number) => number);
	itemList?: { value: number; name: string }[];
	model?: {
		lower: number;
		upper: number;
	};
	isSet?: boolean;
}

export function getStepSize(value: number): number {
	if (value < 2000) return 50; // $100 steps
	if (value < 5000) return 100; // $500 steps
	if (value < 15000) return 250; // $500 steps
	if (value < 50000) return 1000; // $500 steps
	if (value < 75000) return 1500; // $500 steps
	if (value < 100000) return 1000; // $1k steps
	return 5000; // Above $100k: $5k steps
}
